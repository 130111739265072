import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import text from 'global/text.json';
import Typography from '@mui/material/Typography';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { OutputTypesWithVirtualTypes } from 'mid-types';
import { useShallow } from 'zustand/react/shallow';
import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { useOutputSelectionStore } from '@mid-react-common/common';

const rfoModalText = text.rfoModal;

const FileTypesStepFooter: React.FC = () => {
  const theme = useTheme();
  const { isUploadLocationStepCompleted, isFileTypesStepCompleted, setUploadLocationStepActive, setCheckoutStepActive } =
    useRFOModalStore(
      useShallow((state) => ({
        isUploadLocationStepCompleted: state.isUploadLocationStepCompleted,
        isFileTypesStepCompleted: state.isFileTypesStepCompleted,
        setUploadLocationStepActive: state.setUploadLocationStepActive,
        setCheckoutStepActive: state.setCheckoutStepActive,
      })),
    );
  const allOutputs = useOutputSelectionStore((state) => state.allOutputs);

  const numberOfFileTypesSelected = allOutputs.reduce((acc: OutputTypesWithVirtualTypes[], output) => {
    if (!acc.includes(output.type)) {
      acc.push(output.type);
    }

    return acc;
  }, []).length;

  return (
    <Box
      width="100%"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      marginInline={`${theme.var.marginBase}px`}
    >
      <Typography color={theme.palette.text.secondary}>
        {numberOfFileTypesSelected
          ? numberOfFileTypesSelected > 1
            ? `${numberOfFileTypesSelected} ${rfoModalText.fileTypesSelected}`
            : `${numberOfFileTypesSelected} ${rfoModalText.fileTypeSelected}`
          : rfoModalText.noFileTypesSelected}
      </Typography>

      <Box display="flex" gap={`${theme.var.marginBase}px`}>
        <Button
          variant="text"
          endIcon={<EastOutlinedIcon />}
          onClick={setUploadLocationStepActive}
          data-testid={rfoModalTestIds.fileTypesStep.uploadLocationButton}
        >
          {rfoModalText.uploadLocation}
        </Button>
        <Button
          variant="contained"
          endIcon={<ShoppingCartOutlinedIcon />}
          onClick={setCheckoutStepActive}
          disabled={!isUploadLocationStepCompleted || !isFileTypesStepCompleted}
          data-testid={rfoModalTestIds.checkoutButton}
        >
          {rfoModalText.checkout}
        </Button>
      </Box>
    </Box>
  );
};

export default FileTypesStepFooter;
