import Bugsnag from '@bugsnag/js';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getForgeApiServiceInstance } from 'mid-api-services';
import { getDomainFromEmail, logError, midLogger } from 'mid-utils';
import { useCallback, useContext, useEffect, useState } from 'react';
import text from '../../common.text.json';
import NotificationContext from '../../context/NotificationStore/Notification.context';
import { NOTIFICATION_STATUSES } from '../../context/NotificationStore/notificationStore.types';
import { LD_ANALYTICS_KIND } from '../../global/featureFlags';
import { useCancellablePromise } from '../../hooks/misc';
import { analytics } from '../../segment';

interface Analytics {
  analyticsId: string | undefined;
  isAnalyticsIdIdentifiedInLD: boolean;
}

export function useAnalytics(webComponentMoniker: string, sessionId: string): Analytics {
  const { showNotification } = useContext(NotificationContext);

  const [analyticsId, setAnalyticsId] = useState<string | undefined>(undefined);
  const [isAnalyticsIdIdentifiedInLD, setIsAnalyticsIdIdentifiedInLD] = useState(false);

  const ldClient = useLDClient();
  const cancellablePromise = useCancellablePromise();
  const forgeApiService = getForgeApiServiceInstance();

  const setUsersInThirdPartyApps = useCallback(
    async (analyticsId: string, userEmail: string, userOxygenID: string) => {
      // Bugsnag
      Bugsnag.setUser(analyticsId);

      // LaunchDarkly
      if (ldClient && analyticsId) {
        const ldContext = { kind: LD_ANALYTICS_KIND, key: analyticsId };
        await ldClient.identify(ldContext);
        // update mid logger to have ld client.
        midLogger.updateLDClient(ldClient);
        setIsAnalyticsIdIdentifiedInLD(true);
      }

      // Segment
      if (!analytics) {
        return;
      }

      analytics.load({ writeKey: import.meta.env.VITE_SEGMENT_KEY });

      analytics.identify(userOxygenID, {
        userEmail,
        userOxygenID,
        companyName: getDomainFromEmail(userEmail),
      });

      // Page call to capture the initial page load
      analytics.page('page', undefined, {
        sourceMoniker: webComponentMoniker,
        session_id: sessionId,
        url: window.location.href,
      });

      // Gainsight PX / Apptrinsic
      if (window.aptrinsic) {
        window.aptrinsic('identify', {
          id: userOxygenID,
          email: userEmail,
          company: getDomainFromEmail(userEmail),
          analyticsId,
          userOxygenID,
        });
      }
    },
    [ldClient, sessionId, webComponentMoniker],
  );

  useEffect(() => {
    const getAnalyticsIdAndSetUpBugsnag = async () => {
      try {
        const { emailId, userId } = await cancellablePromise(forgeApiService.getUserProfile());
        const fetchedAnalyticsId = await cancellablePromise(forgeApiService.getUserAnalyticsId(userId));
        setAnalyticsId(fetchedAnalyticsId);

        await setUsersInThirdPartyApps(fetchedAnalyticsId, emailId, userId);
      } catch (error) {
        logError(error);
        showNotification({
          message: text.notificationGetUserProfileFailed,
          severity: NOTIFICATION_STATUSES.ERROR,
        });
      }
    };

    if (!analyticsId) {
      getAnalyticsIdAndSetUpBugsnag();
    }
  }, [setAnalyticsId, showNotification, forgeApiService, cancellablePromise, analyticsId, setUsersInThirdPartyApps]);

  return { analyticsId, isAnalyticsIdIdentifiedInLD };
}
