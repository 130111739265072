export const fusionCommands = {
  LOAD_PRODUCT_DEFINITIONS: 'LoadProductDefinitions',
  SAVE_PRODUCT_DEFINITIONS: 'SaveProductDefinitions',
  MODEL_HAS_SWITCHED: 'ModelHasSwitched',
  GET_ACTIVE_DOCUMENT_INFO: 'GetActiveDocumentInfo',
  GET_THUMBNAIL_IMAGE: 'GetThumbnailImage',
  GET_PART_OR_ASSEMBLY_PROPERTIES: 'GetPartOrAssemblyProperties',
  GET_ENVIRONMENT: 'GetEnvironment',
  GET_DC_API_URL: 'GetDcApiUrl',
  GET_MID_WEBAPP_URL: 'GetMIDWebAppUrl',
  FILE_TO_BASE64_STRING: 'FileToBase64String',
  COMPRESS_FOLDER: 'CompressFolder',
  GENERATE_OUTPUTS: 'GenerateOutputs',
  GET_ASSEMBLY_VERSION: 'GetAssemblyVersion',
  OPEN_EXTERNAL_URL: 'OpenExternalUrl',
  DELETE_FILE: 'DeleteFile',
  CHECK_AND_GENERATE_THUMBNAIL_IN_BASE64: 'CheckAndGenerateThumbnailInBase64',
  GET_APPLICATION_VERSION_NUMBER: 'GetApplicationVersionNumber',
  SAVE_TO_FILE: 'SaveToFile',
  GET_REVIT_CLASSIFICATION: 'GetRevitClassification',
  OPEN_PRODUCT_DEFINITION_DOCUMENT: 'OpenProductDefinitionDocument',
  IS_DOCUMENT_OPEN_IN_THE_EDITOR: 'IsDocumentOpenInTheEditor',
  RUN_PUBLISH_CHECKS: 'RunPublishChecks',
  GET_CACHED_OAUTH_TOKENS: 'GetCachedOAuthTokens',
  SET_CACHED_OAUTH_TOKENS: 'SetCachedOAuthTokens',
  DOWNLOAD_FILE_FROM_URL: 'DownloadFileFromUrl',
  DOWNLOAD_FILE_FROM_URL_WITH_NAME: 'DownloadFileFromUrlWithName',
  // #region Export RFA
  GET_ACTIVE_MODEL_STATE_NAME: 'GetActiveModelStateName',
  CREATE_RFA_EXPORT_HISTORY_ITEM: 'CreateRFAExportHistoryItem',
  UPDATE_RFA_EXPORT_HISTORY_ITEM: 'UpdateRFAExportHistoryItem',
  DELETE_RFA_EXPORT_HISTORY_ITEM: 'DeleteRFAExportHistoryItem',
  DELETE_RFA_EXPORT_HISTORY_ITEMS: 'DeleteRFAExportHistoryItems',
  GET_RFA_EXPORT_HISTORY_ITEM: 'GetRFAExportHistoryItem',
  GET_RFA_EXPORT_HISTORY_ITEMS: 'GetRFAExportHistoryItems',
  GET_RFA_EXPORT_HISTORY_LOGS_FOLDER_PATH: 'GetRFAExportHistoryLogsFolderPath',
  EXPORT_ACTIVE_DOCUMENT_AS_BCD: 'ExportActiveDocumentAsBCD',
  OPEN_FILE_LOCATION: 'OpenFileLocation',
  SAVE_FILE_DIALOG: 'SaveFileDialog',
  ACTIVE_DOCUMENT_CONTAINS_SOLID_GEOMETRY: 'ActiveDocumentContainsSolidGeometry',
  GET_RFA_EXPORT_START_MARKER: 'GetRFAExportStartMarker',
  GET_RFA_EXPORT_END_MARKER: 'GetRFAExportEndMarker',
  CONSOLIDATE_RFA_EXPORT_LOGS: 'ConsolidateRFAExportLogs',
  // #endregion
  LOG_TO_FILE: 'LogToFile',
  WRITE_TO_PUBLISH_DATA: 'WriteToPublishData',
  EXTRACT_ZIP_FILE_TO_FOLDER: 'ExtractZipFileToFolder',
} as const;

export type FusionCommand = (typeof fusionCommands)[keyof typeof fusionCommands];

export type CachedOAuthTokens = {
  accessToken: string;
  refreshToken: string;
};
