import {
  DCDrawingOutputs,
  DrawingOutputTypes,
  OutputTypeWithVirtualTypes,
  PostVariantOutputWithVirtualTypes,
} from 'mid-types';
import { SelectChangeEvent } from '@mui/material/Select';
import { handleDrawing2DChange, handleDrawing2DDelete } from '../OutputSelectionPage.utils';

interface UseDrawing2DProps {
  outputsTypeDrawing: DCDrawingOutputs[];
  idwOutputs: string[];
  setIdwOutputs: (idwOutputs: string[]) => void;
  dwgOutputs: string[];
  setDwgOutputs: (dwgOutputs: string[]) => void;
  pdfOutputs: string[];
  setPdfOutputs: (pdfOutputs: string[]) => void;
  allOutputs: PostVariantOutputWithVirtualTypes[];
  setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => void;
}

export interface UseDrawing2DState {
  drawingOutputsByType: { type: DrawingOutputTypes; drawings: string[] }[];
  handleIdwOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleDwgOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handlePdfOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleIdwOutputDelete: (event: any, value: string) => void;
  handleDwgOutputDelete: (event: any, value: string) => void;
  handlePdfOutputDelete: (event: any, value: string) => void;
}

const useDrawing2D = ({
  outputsTypeDrawing,
  allOutputs,
  dwgOutputs,
  idwOutputs,
  pdfOutputs,
  setAllOutputs,
  setDwgOutputs,
  setIdwOutputs,
  setPdfOutputs,
}: UseDrawing2DProps): UseDrawing2DState => {
  const drawingOutputsByType = outputsTypeDrawing.reduce(
    (acc: { type: DrawingOutputTypes; drawings: string[] }[], output) => {
      const type = output.type;
      const foundIndex = acc.findIndex((item) => item.type === type);

      if (foundIndex === -1) {
        acc.push({ type, drawings: [output.options.drawingTemplatePath] });
      } else {
        acc[foundIndex].drawings.push(output.options.drawingTemplatePath);
      }

      return acc;
    },
    [],
  );

  const handleIdwOutputDelete = (event: any, value: string) => {
    handleDrawing2DDelete(value, OutputTypeWithVirtualTypes.IDW, idwOutputs, allOutputs, setIdwOutputs, setAllOutputs);
  };

  const handleDwgOutputDelete = (event: any, value: string) => {
    handleDrawing2DDelete(value, OutputTypeWithVirtualTypes.DWG, dwgOutputs, allOutputs, setDwgOutputs, setAllOutputs);
  };

  const handlePdfOutputDelete = (event: any, value: string) => {
    handleDrawing2DDelete(value, OutputTypeWithVirtualTypes.PDF, pdfOutputs, allOutputs, setPdfOutputs, setAllOutputs);
  };

  const handleIdwOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleDrawing2DChange(
      value,
      OutputTypeWithVirtualTypes.IDW,
      idwOutputs,
      outputsTypeDrawing,
      allOutputs,
      setIdwOutputs,
      setAllOutputs,
    );
  };

  const handleDwgOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleDrawing2DChange(
      value,
      OutputTypeWithVirtualTypes.DWG,
      dwgOutputs,
      outputsTypeDrawing,
      allOutputs,
      setDwgOutputs,
      setAllOutputs,
    );
  };

  const handlePdfOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleDrawing2DChange(
      value,
      OutputTypeWithVirtualTypes.PDF,
      pdfOutputs,
      outputsTypeDrawing,
      allOutputs,
      setPdfOutputs,
      setAllOutputs,
    );
  };

  return {
    drawingOutputsByType,
    handleIdwOutputsChange,
    handleDwgOutputsChange,
    handlePdfOutputsChange,
    handleIdwOutputDelete,
    handleDwgOutputDelete,
    handlePdfOutputDelete,
  };
};

export default useDrawing2D;
