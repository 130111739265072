import { createRoot } from 'react-dom/client';
import { DockingPanel } from '../../components/DockingPanel/DockingPanel';
import { generateDomId } from '../../utils/common';
import { dockingPanelFooterCSSClass, domIdSuffixes } from '../../utils/consts';
import text from '../../utils/text.json';
import { ILmvElement } from '../../utils/types';
import { Button } from '../Button/Button';
import OutputGeneration from './OutputGeneration';

export class OutputGenerationsLmvElement implements ILmvElement {
  _viewer: Autodesk.Viewing.GuiViewer3D;
  _button: Autodesk.Viewing.UI.Button;
  _panel: DockingPanel;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D) {
    this._viewer = viewer;

    this._button = this._createButton();

    this._panel = this._createPanel();
  }

  _createButton(): Autodesk.Viewing.UI.Button {
    const button = new Autodesk.Viewing.UI.Button(generateDomId(domIdSuffixes.outputGenerationButton));

    const reactRoot = createRoot(button.container);

    // remove default icon container, since our buttons are text only
    button.container.removeChild(button.icon);

    // increased width to fit a wider text
    reactRoot.render(<Button text={text.outputGeneration.buttonText} onClick={this._onButtonClick.bind(this)} width={50} />);

    return button;
  }

  _createPanel(): DockingPanel {
    const panel = new DockingPanel(this._viewer, {
      id: generateDomId(domIdSuffixes.outputGenerationPanel),
      title: text.outputGeneration.panelTitle,
      x: 100,
      y: 100,
      width: 500,
      height: 800,
      handleClose: () => {
        this._button.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
      },
    });

    panel.renderReactComponent(<OutputGeneration />);

    const panelFooterElement = panel.container.querySelector(dockingPanelFooterCSSClass) as HTMLElement;
    panelFooterElement.style.height = '60px';
    panelFooterElement.style.zIndex = '2';

    return panel;
  }

  _onButtonClick(): void {
    this._button.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
    this._panel.setVisible(true);
  }

  getButton(): Autodesk.Viewing.UI.Button {
    return this._button;
  }

  uninitialize(): void {
    // removes visibility listeners
    this._panel.uninitialize();
  }
}
