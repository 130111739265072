import { GridFilterModel } from '@mui/x-data-grid';
import { instancesVisibilityFilter } from 'global/constants/common';
import { useEffect, useState } from 'react';

export interface UseProductsInstancesDataGridState {
  instancesFilterModel: GridFilterModel;
}

export interface UseProductsInstancesDataGridProps {
  selectionFilter: string;
}

export const useProductsInstancesDataGrid = ({
  selectionFilter,
}: UseProductsInstancesDataGridProps): UseProductsInstancesDataGridState => {
  const [instancesFilterModel, setInstancesFilterModel] = useState<GridFilterModel>({ items: [] });

  useEffect(() => {
    if (selectionFilter === instancesVisibilityFilter.ALL) {
      setInstancesFilterModel({ items: [] });
    } else {
      setInstancesFilterModel({
        items: [
          {
            field: '__check__',
            operator: 'is',
            value: (selectionFilter === instancesVisibilityFilter.SELECTED).toString(),
          },
        ],
      });
    }
  }, [selectionFilter]);

  return {
    instancesFilterModel,
  };
};
