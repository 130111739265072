import { Variant } from '@adsk/offsite-dc-sdk';
import fractionUnicode from 'fraction-unicode';
import { OutputTypesWithVirtualTypes, OutputTypeWithVirtualTypes, Tree, TreeNode } from 'mid-types';
import text from 'global/text.json';
import { FileGenericIcon, FileTypesIcon } from '@mid-react-common/common';
import { ReactNode } from 'react';
const checkoutStepText = text.rfoModal.checkoutStep;

export const OUTPUT_GENERATION_COST = 3;

export interface TableRowData {
  type: OutputTypesWithVirtualTypes;
  label: string;
  fileExtension: string;
  icon?: ReactNode;
  fileTypeText?: string;
  fileTypeTitle?: string;
  fileTypeQuantity: number;
  outputs?: string[];
  tokenCost: string;
}

export const generateTokenCost = (numberOfOutputs: number): { totalCost: number; totalCostInUnicodeValue: string } => {
  let cost: string = '';
  if (numberOfOutputs % OUTPUT_GENERATION_COST === 0) {
    cost = (numberOfOutputs / OUTPUT_GENERATION_COST).toString();
  } else {
    const wholeNumber = Math.floor(numberOfOutputs / OUTPUT_GENERATION_COST);
    const remainder = numberOfOutputs % OUTPUT_GENERATION_COST;

    let simplifiedDenominator = OUTPUT_GENERATION_COST;
    let simplifiedNumerator = remainder;
    while (simplifiedNumerator !== 1 && OUTPUT_GENERATION_COST % remainder === 0) {
      simplifiedDenominator = simplifiedDenominator / remainder;
      simplifiedNumerator = simplifiedNumerator / remainder;
    }
    const fraction = fractionUnicode(simplifiedNumerator, simplifiedDenominator);
    cost = wholeNumber !== 0 ? `${wholeNumber} ${fraction}` : fraction;
  }

  return { totalCost: numberOfOutputs / OUTPUT_GENERATION_COST, totalCostInUnicodeValue: cost };
};

const typeToIconAndLabelMap: Partial<{
  [key in OutputTypesWithVirtualTypes]: {
    label: string;
    fileExtension: string;
    icon?: ReactNode;
    fileTypeTitle?: string;
    fileTypeText?: string;
  };
}> = {
  [OutputTypeWithVirtualTypes.IAM]: {
    label: checkoutStepText.inventorDataSet,
    fileExtension: checkoutStepText.zip,
  },
  [OutputTypeWithVirtualTypes.DWG]: {
    label: checkoutStepText.autocadNative,
    fileExtension: checkoutStepText.dwg,
    icon: <FileGenericIcon />,
    fileTypeTitle: checkoutStepText.drawingTemplates,
    fileTypeText: checkoutStepText.drawings,
  },
  [OutputTypeWithVirtualTypes.IDW]: {
    label: checkoutStepText.inventorNative,
    fileExtension: checkoutStepText.idw,
    icon: <FileGenericIcon />,
    fileTypeTitle: checkoutStepText.drawingTemplates,
    fileTypeText: checkoutStepText.drawings,
  },
  [OutputTypeWithVirtualTypes.PDF]: {
    label: checkoutStepText.neutralPDF,
    fileExtension: checkoutStepText.pdf,
    icon: <FileGenericIcon />,
    fileTypeTitle: checkoutStepText.drawingTemplates,
    fileTypeText: checkoutStepText.drawings,
  },
  [OutputTypeWithVirtualTypes.BOM]: {
    label: checkoutStepText.instanceLevelBOM,
    fileExtension: checkoutStepText.csv,
    icon: <FileTypesIcon />,
    fileTypeTitle: checkoutStepText.representationsTitle,
    fileTypeText: checkoutStepText.representations,
  },
  [OutputTypeWithVirtualTypes.BOMAGGREGATED]: {
    label: checkoutStepText.aggregated,
    fileExtension: checkoutStepText.csv,
    icon: <FileTypesIcon />,
    fileTypeTitle: checkoutStepText.representationsTitle,
    fileTypeText: checkoutStepText.representations,
  },
  [OutputTypeWithVirtualTypes.SAT]: {
    label: checkoutStepText.neutralSAT,
    fileExtension: checkoutStepText.sat,
    icon: <FileTypesIcon />,
    fileTypeTitle: checkoutStepText.representationsTitle,
    fileTypeText: checkoutStepText.representations,
  },
  [OutputTypeWithVirtualTypes.GLB]: {
    label: checkoutStepText.neutralGLB,
    fileExtension: checkoutStepText.glb,
    icon: <FileTypesIcon />,
    fileTypeTitle: checkoutStepText.representationsTitle,
    fileTypeText: checkoutStepText.representations,
  },
  [OutputTypeWithVirtualTypes.STEP]: {
    label: checkoutStepText.neutralStep,
    fileExtension: checkoutStepText.step,
    icon: <FileTypesIcon />,
    fileTypeTitle: checkoutStepText.representationsTitle,
    fileTypeText: checkoutStepText.representations,
  },
};

export const createTableData = (
  isIamSelected: boolean,
  allOutputs: {
    outputs: string[];
    type: OutputTypesWithVirtualTypes;
  }[],
  selectedVariants: Variant[],
): TableRowData[] => {
  const tableData: TableRowData[] = [];

  if (isIamSelected) {
    tableData.push({
      type: OutputTypeWithVirtualTypes.IAM,
      label: checkoutStepText.inventorDataSet,
      fileExtension: checkoutStepText.zip,
      fileTypeQuantity: selectedVariants.length,
      tokenCost: generateTokenCost(selectedVariants.length).totalCostInUnicodeValue,
    });
  }

  for (const outputType of allOutputs) {
    if (outputType.outputs.length > 0) {
      const outputTypeInfo = typeToIconAndLabelMap[outputType.type];
      if (outputTypeInfo) {
        tableData.push({
          type: outputType.type,
          label: outputTypeInfo.label,
          fileExtension: outputTypeInfo.fileExtension,
          icon: outputTypeInfo.icon,
          fileTypeTitle: outputTypeInfo.fileTypeTitle,
          fileTypeText: outputTypeInfo.fileTypeText,
          fileTypeQuantity: outputType.outputs.length * selectedVariants.length,
          outputs: outputType.outputs,
          tokenCost: generateTokenCost(outputType.outputs.length * selectedVariants.length).totalCostInUnicodeValue,
        });
      }
    }
  }

  return tableData;
};

export const getFolderNameAndFullPath = (
  folderUrn: string | undefined,
  uploadLocationTree: Tree | undefined,
): { folderName: string | null; fullPath: TreeNode[] | null } => {
  if (!folderUrn || !uploadLocationTree) {
    return { folderName: null, fullPath: null };
  }

  let selectedFolderUrnLabel = '';
  let current = folderUrn;

  const fullPath: TreeNode[] = [];
  Object.keys(uploadLocationTree)
    .reverse()
    .forEach((parentFolder) => {
      for (const childFolder of uploadLocationTree[parentFolder]) {
        if (childFolder.id === folderUrn) {
          selectedFolderUrnLabel = childFolder.label;
        }
        if (childFolder.id === current) {
          fullPath.push(childFolder);
          current = parentFolder;
        }
      }
    });

  return { folderName: selectedFolderUrnLabel, fullPath: fullPath.length > 1 ? fullPath.slice(1).reverse() : null };
};
