import { NOTIFICATION_STATUSES, NotificationContext } from '@mid-react-common/common';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { ALL_VARIANTS_OUTPUTS_STATUS_KEY, VARIANT_OUTPUTS_UPLOAD_RESULTS_KEY } from 'global/constants/reactQueryKeys';
import { isUndefined } from 'lodash';
import { isFulfilled, logError } from 'mid-utils';
import { useContext, useEffect, useMemo, useState, useCallback } from 'react';
import ProductContext from '../../../context/ProductStore/Product.context';
import text from '../../../global/text.json';
import {
  OutputUploadRequestMap,
  OutputsReviewTableDataModel,
  useVariantStatusPollingParams,
  useVariantStatusPollingReturn,
} from './Types';
import {
  UPLOAD_TIMESTAMP_FORMAT,
  VARIANT_OUTPUT_UPLOAD_STATUS_POLLING_INTERVAL,
  aggregateUploadResultsPerOutputAndReturnLatest,
  getOutputUploadRequestsForAllVariants,
  getUniqueIdentifierForOutputUploadRequestAggregation,
  getVariantOutputs,
  shouldVariantOutputsPollingContinue,
  shouldVariantOutputsUploadStatusPollingContinue,
  transformInstancesToOutputsReviewMap,
  transformModelState,
} from './useVariantStatusPolling.utils';
import { getBridgeAutomationFromVariantId, transformInstancesToVariantBridgeMap } from 'utils/bridge';
import { VariantInstanceAccBridgeMetadata } from 'types/bridge';
import { OutputType } from '@adsk/offsite-dc-sdk';

const reviewPanelText = text.reviewPanel;

export function useVariantStatusPolling({
  instances,
  projectId,
}: useVariantStatusPollingParams): useVariantStatusPollingReturn {
  const [variantOutputsLoading, setVariantOutputsLoading] = useState(true);
  const { showNotification } = useContext(NotificationContext);
  const { productReleases, selectedProductRelease } = useContext(ProductContext);
  const [outputUploadRequestMap, setOutputUploadRequestMap] = useState<OutputUploadRequestMap>({});
  const selectedContentId = selectedProductRelease?.contentId || '';
  const [shouldStartPolling, setShouldStartPolling] = useState(false);

  const transformedInstances = useMemo(() => {
    if (instances && selectedProductRelease && selectedContentId) {
      return transformInstancesToOutputsReviewMap(instances, selectedProductRelease);
    }
    return null;
  }, [selectedProductRelease, instances, selectedContentId]);

  const variantAccBridgeMap: Map<string, VariantInstanceAccBridgeMetadata> | undefined = useMemo(() => {
    if (!instances) {
      return;
    }

    return transformInstancesToVariantBridgeMap(instances);
  }, [instances]);

  const queryAllVariantsOutputStatusFunction = useCallback(async (): Promise<OutputsReviewTableDataModel[]> => {
    if (projectId && selectedProductRelease && selectedContentId && transformedInstances) {
      // Retrieve output status for each variant
      const allVariantOutputsPromise = [];
      for (const currentVariantId of Object.keys(transformedInstances)) {
        const accBridgeData = getBridgeAutomationFromVariantId(currentVariantId, variantAccBridgeMap);

        try {
          const variantStatusMetadata = getVariantOutputs({
            projectId,
            productId: selectedContentId,
            variantId: currentVariantId,
            incomingAccBridgeData: accBridgeData,
          });
          allVariantOutputsPromise.push(variantStatusMetadata);
        } catch (err) {
          logError(reviewPanelText.failToGetVariant, {
            err,
            projectId,
            productId: selectedContentId,
            currentVariantId,
          });
          showNotification({
            message: `${reviewPanelText.failToGetVariant} ${currentVariantId}`,
            severity: NOTIFICATION_STATUSES.ERROR,
          });
        }
      }
      const allSettledVariantOutcomes = await Promise.allSettled(allVariantOutputsPromise);

      const succeededVariantOutputs = allSettledVariantOutcomes.filter(isFulfilled);
      const succeededVariantOutputsValues = succeededVariantOutputs.map((output) => output.value);

      const allVariantsOutputStatus: OutputsReviewTableDataModel[] = [];
      succeededVariantOutputsValues.forEach((outputStatusArray) => {
        outputStatusArray.forEach((outputStatus, index) => {
          const currentVariantId = outputStatus.variantId;
          const outputToUpdateIndex = transformedInstances[currentVariantId].findIndex(
            (currentMetadata) =>
              currentMetadata.variantId === outputStatus.variantId &&
              currentMetadata.modelState === outputStatus.modelState &&
              currentMetadata.drawingPath === outputStatus.drawingPath &&
              currentMetadata.fileType === outputStatus.fileType,
          );
          transformedInstances[currentVariantId][outputToUpdateIndex] = {
            ...transformedInstances[currentVariantId][outputToUpdateIndex],
            ...outputStatus,
          };

          // If it's the last one push the updates
          if (index === outputStatusArray.length - 1) {
            allVariantsOutputStatus.push(...transformedInstances[currentVariantId]);
          }
        });
      });

      return allVariantsOutputStatus;
    }
    return [];
  }, [projectId, selectedContentId, selectedProductRelease, showNotification, transformedInstances, variantAccBridgeMap]);

  const { data: polledAllVariantsOutputs } = useQuery<OutputsReviewTableDataModel[]>({
    queryKey: [ALL_VARIANTS_OUTPUTS_STATUS_KEY],
    queryFn: queryAllVariantsOutputStatusFunction,
    enabled: shouldStartPolling,
    refetchInterval: (current: any) => {
      if (!current.state.data || (current.state.data && shouldVariantOutputsPollingContinue(current.state.data))) {
        return VARIANT_OUTPUT_UPLOAD_STATUS_POLLING_INTERVAL;
      }
      return false;
    },
  });

  const variantOutputsUploadResults = useQuery({
    queryKey: [VARIANT_OUTPUTS_UPLOAD_RESULTS_KEY],
    queryFn: async ({ signal }) =>
      await getOutputUploadRequestsForAllVariants({
        transformedInstances,
        selectedContentId,
        projectId,
        variantAccBridgeMap,
        signal,
      }),
    enabled: shouldStartPolling,
    refetchInterval: (current) => {
      if (
        polledAllVariantsOutputs &&
        current.state.data &&
        shouldVariantOutputsUploadStatusPollingContinue(current.state.data)
      ) {
        return VARIANT_OUTPUT_UPLOAD_STATUS_POLLING_INTERVAL;
      }

      return false;
    },
  });

  useEffect(() => {
    // it makes no sense to start polling if no instances available (this is the case when user refreshes the
    // screen with the openModel URL Parameter and the logic to retrieve MID elements is not started yet)
    if (!instances) {
      setVariantOutputsLoading(false);
      return;
    }

    if (selectedContentId) {
      if (!polledAllVariantsOutputs) {
        setVariantOutputsLoading(true);
        setShouldStartPolling(true);
      } else {
        setVariantOutputsLoading(false);
      }
    }
  }, [polledAllVariantsOutputs, instances, productReleases, selectedContentId, setShouldStartPolling]);

  useEffect(() => {
    if (variantOutputsUploadResults.data) {
      const uploadRequestMap = aggregateUploadResultsPerOutputAndReturnLatest(variantOutputsUploadResults.data);
      setOutputUploadRequestMap(uploadRequestMap);
    }
  }, [variantOutputsUploadResults.data]);

  // TRADES-7346: Filtering out SVF outputs and the outputs that have not been generated yet (not status)
  const polledAllVariantsOutputsWithLatestUploadTimeAndOutputLocation = polledAllVariantsOutputs
    ?.filter((variantOutputs) => !isUndefined(variantOutputs.status) && variantOutputs.fileType !== OutputType.SVF)
    .map((output) => {
      const aggregatedOutputUniqueId = getUniqueIdentifierForOutputUploadRequestAggregation(
        output.contentId,
        output.variantId,
        output.fileType,
        output.modelState,
        output.drawingPath,
      );

      if (aggregatedOutputUniqueId && aggregatedOutputUniqueId in outputUploadRequestMap) {
        const outputUploadRequest = outputUploadRequestMap[aggregatedOutputUniqueId];
        return {
          ...output,
          modelState: transformModelState(output.modelState),
          modifiedAt: format(new Date(outputUploadRequest.updatedAt), UPLOAD_TIMESTAMP_FORMAT),
          outputUploadStatus: outputUploadRequest.outputUploadStatus,
          outputLocation: outputUploadRequest.outputLocation,
          outputLocationAccountId: outputUploadRequest.accountId,
          outputLocationProjectId: outputUploadRequest.projectId,
          outputProjectVendor: outputUploadRequest.outputProjectVendor,
        };
      }

      return { ...output, modelState: transformModelState(output.modelState) };
    });

  return {
    variantOutputsLoading,
    // Initially, I wanted to only poll for the outputs that were selected by the user
    // This has 2 problems :
    // 1. In order to show all cached and previously generated outputs in the review panel,
    // the user would have to reselect the outputs everytime and regenerate them

    // 2. If the users selects different outputs to generate, the old outputs would not show up in the table
    // because we only poll for the selected outputs and the old would be overwritten.

    // With the current implementation, we have to poll for all outputs
    // and then filter out the ones that have not been generate yet
    // We have no way of knowing which outputs the user selected to generate in the past
    polledAllVariantsOutputs: polledAllVariantsOutputsWithLatestUploadTimeAndOutputLocation,
  };
}
