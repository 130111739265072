import Chip from '@mui/material/Chip';
import text from '../../../global/text.json';
import { GridColDef, GridComparatorFn, GridRenderCellParams } from '@mui/x-data-grid';
import { format, parseISO } from 'date-fns';
import { BIM360Document } from 'mid-types';
import { MODELS_FOLDER_CONTENT_TIME_FORMAT, MODELS_FOLDER_FILENAME_EXTENSION } from '../../../global/constants/model';
import RenderFilenameCell from './RenderFilenameCell';
import { FolderContentRow } from './ModelsFolderContent.types';

const modelsFolderText = text.modelsFolderContent;

export const modifiedTimeComparator: GridComparatorFn<string> = (firstModifiedTime, secondModifiedTime) => {
  if (!firstModifiedTime) {
    return -1;
  }

  if (!secondModifiedTime) {
    return 1;
  }

  const firstDate = new Date(firstModifiedTime);
  const secondDate = new Date(secondModifiedTime);

  return firstDate.getTime() - secondDate.getTime();
};

export const columns: GridColDef[] = [
  {
    field: 'fileName',
    headerName: modelsFolderText.name,
    width: 370,
    renderCell: RenderFilenameCell,
  },
  {
    field: 'version',
    headerName: modelsFolderText.version,
    width: 70,
    renderCell: ({ value }: GridRenderCellParams): React.ReactNode => <Chip label={value} size="small" />,
    align: 'center',
  },
  {
    field: 'modifiedOn',
    headerName: modelsFolderText.lastUpdated,
    width: 150,
    sortComparator: modifiedTimeComparator,
  },
];

export const transformLMVModelFileListInFolderContent = (lmvModelFileList: BIM360Document[]): FolderContentRow[] =>
  lmvModelFileList
    .filter((lmvModelFile) => lmvModelFile.file_name.toLowerCase().endsWith(MODELS_FOLDER_FILENAME_EXTENSION))
    .map((lmvModelFile: BIM360Document) => {
      const { id, urn, name, latest_version_create_time, latest_version } = lmvModelFile;

      const parsedUpdatedTime = parseISO(latest_version_create_time);
      const formattedUpdatedTime = format(parsedUpdatedTime, MODELS_FOLDER_CONTENT_TIME_FORMAT);
      const lmvModelFileId = btoa(lmvModelFile.current_version.bubble_urn).replace(/\//g, '_');

      return {
        id,
        urn,
        fileName: name,
        version: latest_version.toString(),
        modifiedOn: formattedUpdatedTime,
        lmvModelFileId,
      };
    });
