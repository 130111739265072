import { EmptyOutputFileError, logError, processAllSettledResults, VariantLogsFetchError } from 'mid-utils';
import text from '../global/text.json';
import { DownloadURLPayload, DownloadUrlResponse, Variant } from '@adsk/offsite-dc-sdk';
import { DcApiService, getDcApiServiceInstance, inversifyContainer, InversifyTypes } from 'mid-api-services';
import { AccBridgeSourceProjectDataQueryParams, BatchFetchVariantsResult } from 'mid-types';
import { getBridgeDataFromInstance } from 'utils/bridge';
import { ProductInstanceAccBridgeMetadata } from 'types/bridge';

const variantsServiceText = text.variantsService;

interface GetVariantsListArgs {
  projectId: string;
  productId: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}

export async function getVariantsList({
  projectId,
  productId,
  incomingAccBridgeData,
}: GetVariantsListArgs): Promise<Variant[]> {
  const dcApiService = inversifyContainer.get<DcApiService>(InversifyTypes.DcApiService);
  return await dcApiService.getVariantsList({ projectId, productId, incomingAccBridgeData });
}

export async function batchFetchVariants(
  productInstanceList: ProductInstanceAccBridgeMetadata[],
): Promise<BatchFetchVariantsResult | null> {
  if (productInstanceList.length === 0) {
    return null;
  }

  const resultsPromise = productInstanceList.map(async (productInstanceParameters) => {
    const bridgeAutomationParams = getBridgeDataFromInstance(productInstanceParameters);

    return getVariantsList({
      projectId: productInstanceParameters.projectId,
      productId: productInstanceParameters.productId,
      incomingAccBridgeData: bridgeAutomationParams,
    });
  });

  const results = await Promise.allSettled<Variant[]>(resultsPromise);
  return processAllSettledResults(results);
}

export const getVariantLogsText = async (fileUrl: string): Promise<string> => {
  try {
    const response = await fetch(new URL(fileUrl));
    return (await response).text();
  } catch (e) {
    logError(e);
    throw new VariantLogsFetchError(variantsServiceText.variantOutputsLogFile, { fileUrl });
  }
};

interface getVariantLogsResponse {
  fileUrl: string;
  logData: string;
}

export const getVariantsLogs = async (
  projectId: string,
  productId: string,
  variantId: string,
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams,
): Promise<getVariantLogsResponse> => {
  const { urn } = await getDcApiServiceInstance().getVariantLog({
    projectId,
    productId,
    variantId,
    incomingAccBridgeData,
  });
  const downloadURLPayload: DownloadURLPayload = {
    objectKey: urn,
  };
  const downloadUrlResponse: DownloadUrlResponse = await getDcApiServiceInstance().downloadURL({
    projectId,
    downloadURLPayload,
    incomingAccBridgeData,
  });

  if (!downloadUrlResponse.signedUrl) {
    throw new EmptyOutputFileError(text.reviewPanel.emptyFileUrlFromAPI);
  }

  const fileUrl = downloadUrlResponse.signedUrl;
  const logData = await getVariantLogsText(fileUrl);

  if (!logData) {
    throw new EmptyOutputFileError(text.reviewPanel.emptyFileUrlFromAPI);
  }

  return { fileUrl, logData };
};
