import { OutputSelectionContextProvider, OutputSelectionPage } from '@mid-react-common/common';
import React from 'react';
import { createPortal } from 'react-dom';
import { useShallow } from 'zustand/react/shallow';
import { useMainStore } from '../../stores/MainStore';
import { generateDomId } from '../../utils/common';
import { dockingPanelFooterCSSClass, domIdSuffixes } from '../../utils/consts';
import { OutputGenerationContainer } from './OutputGeneration.styles';
import OutputGenerationFooter from './OutputGenerationFooter';

const OutputGeneration: React.FC = () => {
  const { currentProductRelease } = useMainStore(
    useShallow((state) => ({
      currentProductRelease: state.currentProductRelease,
    })),
  );

  const dockingPanelFooter = document
    .getElementById(generateDomId(domIdSuffixes.outputGenerationPanel))
    ?.querySelector(dockingPanelFooterCSSClass);

  const FooterToRender = dockingPanelFooter ? (
    createPortal(<OutputGenerationFooter />, dockingPanelFooter)
  ) : (
    <OutputGenerationFooter />
  );

  return (
    <OutputSelectionContextProvider>
      <OutputGenerationContainer>
        <OutputSelectionPage selectedProductRelease={currentProductRelease} />
      </OutputGenerationContainer>
      {FooterToRender}
    </OutputSelectionContextProvider>
  );
};

export default OutputGeneration;
