import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import text from '../../../common.text.json';
import testIds from '../../../tests/helpers/dataTestIds';
import { useOutputSelectionStore } from '../OutputSelectionContext/OutputSelectionContext';
import { Section } from '../OutputSelectionPage.styles';
import useModel3D from './useModel3D';

const outputSelectionText = text.outputSelection;

const Model3D: React.FC = () => {
  const theme = useTheme();

  const { isIamSelected, setIsIamSelected, allOutputs, setAllOutputs } = useOutputSelectionStore((state) => ({
    isIamSelected: state.isIamSelected,
    setIsIamSelected: state.setIsIamSelected,
    allOutputs: state.allOutputs,
    setAllOutputs: state.setAllOutputs,
  }));
  const { handleInventorDataSetChange } = useModel3D({ setIsIamSelected, allOutputs, setAllOutputs });

  return (
    <Section data-testid={testIds.outputSelection.model3DSection}>
      <Typography fontWeight="bold" variant="h3" gutterBottom>
        {outputSelectionText.model3d}
      </Typography>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={isIamSelected} onChange={handleInventorDataSetChange} />}
          label={<Typography color={theme.palette.text.secondary}>{outputSelectionText.model3dCheckboxLabel}</Typography>}
        />
        <Typography color={theme.palette.text.secondary}>{outputSelectionText.model3dInfo}</Typography>
      </FormGroup>
    </Section>
  );
};

export default Model3D;
