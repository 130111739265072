import Close from '@mui/icons-material/Close';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { GridRowId } from '@mui/x-data-grid';
import CustomAccordion from 'components/Commons/CustomAccordion/CustomAccordion';
import CustomPagination from 'components/Commons/CustomPagination/CustomPagination';
import { DetailsSidebarDivider, DetailsSidebarWrapper } from 'components/Commons/DetailsSidebar/DetailsSidebar.style';
import DetailsSidebarContent from 'components/Commons/DetailsSidebar/DetailsSidebarContent';
import DetailsSidebarHeader from 'components/Commons/DetailsSidebar/DetailsSidebarHeader';
import SidebarThumbnail from 'components/Commons/SidebarThumbnail/SidebarThumbnail';
import { SidebarThumbnailContainer } from 'components/Commons/SidebarThumbnail/SidebarThumbnail.style';
import MissingThumbnail from 'images/missingThumbnail.jpg';
import { detailsSidebarIds, releasesDetailsIds } from 'tests/helpers/dataTestIds';
import text from '../../../global/text.json';
import { releaseInfo } from './ReleaseDetails.utils';
import useReleaseDetails from './useReleaseDetails';
import KeyValueStack from 'components/Commons/KeyValueStack/KeyValueStack';
import { DCProductUIExtension } from 'mid-types';
import { DynamicContent, ProductRelease } from '@adsk/offsite-dc-sdk';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import { useFlags } from 'launchdarkly-react-client-sdk';

const releasesText = text.releases;

interface ReleasesDetailsProps {
  productReleases: DCProductUIExtension<ProductRelease>[];
  selectedProduct: DCProductUIExtension<DynamicContent> | null;
  selectedReleasesIds: GridRowId[];
  detailsVisibility: boolean;
  handleCloseDetails: () => void;
  refetchProductReleases: (
    options?: RefetchOptions,
  ) => Promise<QueryObserverResult<DCProductUIExtension<ProductRelease>[], Error>>;
}

const ReleasesDetails: React.FC<ReleasesDetailsProps> = ({
  productReleases,
  selectedProduct,
  selectedReleasesIds,
  detailsVisibility,
  handleCloseDetails,
  refetchProductReleases,
}) => {
  const { enableStaticContent } = useFlags();
  const {
    isThumbnailLoading,
    parameterVisibility,
    releaseFullPath,
    selectedIndex,
    thumbnail,
    currentRelease,
    handleShowAllClick,
    handleReleaseDetailsPageChange,
  } = useReleaseDetails({ productReleases, selectedProduct, selectedReleasesIds });

  const releaseInfoData =
    currentRelease &&
    releaseInfo({
      currentRelease,
      parameterVisibility,
      releaseFullPath,
      handleShowAllClick,
      productReleases,
      refetchProductReleases,
      enableStaticContent,
    });

  return (
    <>
      {currentRelease && selectedReleasesIds.length > 0 && detailsVisibility && (
        <DetailsSidebarWrapper data-testid={detailsSidebarIds.detailsSidebarWrapper}>
          <DetailsSidebarHeader>
            <Typography variant="h2">{releasesText.releaseDetails}</Typography>
            <Box display="flex">
              <CustomPagination
                count={selectedReleasesIds.length}
                currentIndex={selectedIndex}
                onChange={handleReleaseDetailsPageChange}
              />
              <IconButton onClick={handleCloseDetails} data-testid={releasesDetailsIds.releaseDetailsCloseButton}>
                <Close />
              </IconButton>
            </Box>
          </DetailsSidebarHeader>
          <DetailsSidebarDivider />
          <DetailsSidebarContent>
            <CustomAccordion
              title={releasesText.releaseDetailsThumbnail}
              data-testid={releasesDetailsIds.releaseDetailsThumbnail}
            >
              {isThumbnailLoading ? (
                <SidebarThumbnailContainer className="mid-border-shadow">
                  <CircularProgress />
                </SidebarThumbnailContainer>
              ) : (
                <SidebarThumbnail src={thumbnail || MissingThumbnail} />
              )}
            </CustomAccordion>
            <CustomAccordion
              title={releasesText.releaseDetailsSummary}
              data-testid={releasesDetailsIds.releaseDetailsSummary}
            >
              <KeyValueStack data={releaseInfoData || []} />
            </CustomAccordion>
          </DetailsSidebarContent>
        </DetailsSidebarWrapper>
      )}
    </>
  );
};

export default ReleasesDetails;
