import { ReactElement } from 'react';
import { createRoot } from 'react-dom/client';

import { useThemeStore } from '../../stores/ThemeStore';
import themeConsts from '../../theme/consts';
import { wrapAllReactContexts } from '../../utils/common';
import { dockingPanelFooterCSSClass } from '../../utils/consts';

type Options = {
  title: string;
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
  handleClose?: () => void;
};

export class DockingPanel extends Autodesk.Viewing.UI.DockingPanel {
  _viewer: Autodesk.Viewing.GuiViewer3D;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D, options: Options) {
    super(viewer.container, options.id, options.title);

    this._viewer = viewer;

    this._setContainerStyles(options);

    let unsubscribe: any;

    // listen to theme changes in order to update the non-react LMV UI elements
    unsubscribe = useThemeStore.subscribe(() => {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = undefined;
      }
      this._setThemeStyles();
    });

    if (options.handleClose) {
      this.addVisibilityListener((show: boolean) => {
        if (!show) {
          options.handleClose!();
        }
      });
    }

    this._initialize();
  }

  _setThemeStyles(): void {
    this.container.style.backgroundColor =
      useThemeStore.getState().theme === 'dark-theme'
        ? themeConsts.darkThemePanelBackground
        : themeConsts.lightThemePanelBackground;
  }

  _setContainerStyles(options: Options): void {
    this.container.style.height = options.height + 'px';
    this.container.style.width = options.width + 'px';
    this.container.style.resize = 'none';
    this.container.style.left = options.x + 'px';
    this.container.style.top = options.y + 'px';

    this._setThemeStyles();
  }

  _initialize(): void {
    this.content = document.createElement('div');

    // flex grow for the container to fill the remaining space
    // this allows loading filler and spinner to be centered
    this.content.style.flexGrow = '1';

    const footer = this.container.querySelector(dockingPanelFooterCSSClass) as HTMLElement;

    // insert container before the footer
    this.container.insertBefore(this.content, footer);
  }

  renderReactComponent(component: ReactElement): void {
    const reactRoot = createRoot(this.content);
    reactRoot.render(wrapAllReactContexts(component, this._viewer.container));
  }
}
