import { Spacing } from '@mid-react-common/common';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DCBomOutputs, OutputTypeWithVirtualTypes } from 'mid-types';
import text from '../../../common.text.json';
import testIds from '../../../tests/helpers/dataTestIds';
import { Section } from '../OutputSelectionPage.styles';
import BillOfMaterialDropdown from './BillOfMaterialDropdown';
import useBillOfMaterials from './useBillOfMaterials';
import { useOutputSelectionStore } from '../OutputSelectionContext/OutputSelectionContext';

const outputSelectionText = text.outputSelection;

interface BillOfMaterialsProps {
  outputsTypeBom: DCBomOutputs[];
}

const BillOfMaterials: React.FC<BillOfMaterialsProps> = ({ outputsTypeBom }) => {
  const { aggregatedBomOutputs, allOutputs, bomOutputs, setAggregatedBomOutputs, setAllOutputs, setBomOutputs } =
    useOutputSelectionStore((state) => ({
      aggregatedBomOutputs: state.aggregatedBomOutputs,
      allOutputs: state.allOutputs,
      bomOutputs: state.bomOutputs,
      setAggregatedBomOutputs: state.setAggregatedBomOutputs,
      setAllOutputs: state.setAllOutputs,
      setBomOutputs: state.setBomOutputs,
    }));

  const {
    handleAggregatedBomOutputsChange,
    handleBomOutputsChange,
    handleAggregatedBomOutputDelete,
    handleBomOutputDelete,
  } = useBillOfMaterials({
    outputsTypeBom,
    aggregatedBomOutputs,
    allOutputs,
    bomOutputs,
    setAggregatedBomOutputs,
    setAllOutputs,
    setBomOutputs,
  });

  return (
    <Section data-testid={testIds.outputSelection.bomSection}>
      <Typography fontWeight="bold" variant="h3">
        {outputSelectionText.billOfMaterials}
      </Typography>
      <Spacing />
      <Box>
        {outputsTypeBom.map((outputsByType, index) => {
          switch (outputsByType.type) {
            case OutputTypeWithVirtualTypes.BOM:
              return (
                <BillOfMaterialDropdown
                  key={`${outputsByType.type}-${index}`}
                  label={outputSelectionText.instanceLevelBOM}
                  modelStates={outputsByType.options.modelStates}
                  selectedModelStates={bomOutputs}
                  handleOnChange={handleBomOutputsChange}
                  handleDelete={handleBomOutputDelete}
                />
              );
            case OutputTypeWithVirtualTypes.BOMAGGREGATED:
              return (
                <BillOfMaterialDropdown
                  key={`${outputsByType.type}-${index}`}
                  label={outputSelectionText.aggregatedBOM}
                  modelStates={outputsByType.options.modelStates}
                  selectedModelStates={aggregatedBomOutputs}
                  handleOnChange={handleAggregatedBomOutputsChange}
                  handleDelete={handleAggregatedBomOutputDelete}
                />
              );
          }
        })}
      </Box>
    </Section>
  );
};

export default BillOfMaterials;
