import { ProductRelease, ReleaseStatus, Variant } from '@adsk/offsite-dc-sdk';
import { NotificationContext, StateSetter } from '@mid-react-common/common';
import { isEqual, isUndefined } from 'lodash';
import { useCallback, useContext, useEffect, useState } from 'react';
import text from '../../global/text.json';
import { getAllProductReleases } from '../../services/products';
import { batchFetchVariants } from '../../services/variants';
import { Instance } from '../../types/product';
import { logError } from 'mid-utils';
// TODO: TRADES-7329 mid-webapp should not have mid-addin-lib dependency
import { ProductsUtils } from 'mid-addin-lib';
import {
  getSelectedProductReleaseFromLocalStorage,
  removeSelectedProductReleaseFromLocalStorage,
  setSelectedProductReleaseToLocalStorage,
} from './productStore.utils';
import { ProductInstanceAccBridgeMetadata } from 'types/bridge';
import { getBridgeDataFromInstance } from 'utils/bridge';
import { DCProductUIExtension } from 'mid-types';

const productStoreText = text.productsStore;
export interface ProductStore {
  productReleases: DCProductUIExtension<ProductRelease>[];
  variants: Variant[];
  productsLoading: boolean;
  dataGridInstances: Instance[];
  setDataGridInstances: StateSetter<Instance[]>;
  selectedProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  instances: Instance[] | undefined;
  setInstances: StateSetter<Instance[] | undefined>;
  selectedInstances: Instance[] | undefined;
  setSelectedInstances: StateSetter<Instance[] | undefined>;
  resetProductStoreState: () => void;
  handleProductReleaseSelection: (productRelease: ProductRelease | undefined) => void;
}

export const useProductStore = (): ProductStore => {
  const { logAndShowNotification } = useContext(NotificationContext);
  const [productReleases, setProductReleases] = useState<DCProductUIExtension<ProductRelease>[]>([]);
  const [variants, setVariants] = useState<Variant[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(false);
  // Instances are the elements that we extract from LMV
  // We need to set instances undefined initially to indicate
  // that this data is not initialized yet. Once it is initialized,
  // it could only be empty array or an array of instances.
  const [instances, setInstances] = useState<Instance[] | undefined>();
  const [selectedProductRelease, setSelectedProductRelease] = useState<DCProductUIExtension<ProductRelease> | undefined>();
  const [dataGridInstances, setDataGridInstances] = useState<Instance[]>([]);
  const [selectedInstances, setSelectedInstances] = useState<Instance[] | undefined>();

  const handleProductReleaseSelection = useCallback(
    (productRelease: DCProductUIExtension<ProductRelease> | undefined) => {
      setSelectedProductRelease(productRelease);

      if (!productRelease) {
        return removeSelectedProductReleaseFromLocalStorage();
      }

      setSelectedProductReleaseToLocalStorage(productRelease);
    },
    [setSelectedProductRelease],
  );

  const resetProductStoreState = useCallback(() => {
    setProductReleases([]);
    setVariants([]);
    setInstances(undefined);
    handleProductReleaseSelection(undefined);
    setProductsLoading(false);
  }, [handleProductReleaseSelection]);

  const fetchProductsData = useCallback(async () => {
    if (instances && instances.length > 0) {
      try {
        setProductsLoading(true);

        //Find list of unique ProductId's
        const uniqueProducts = new Map<string, ProductInstanceAccBridgeMetadata>();
        instances.forEach((currentInstance) => {
          if (!uniqueProducts.has(currentInstance.contentId)) {
            uniqueProducts.set(currentInstance.contentId, {
              productId: currentInstance.contentId,
              projectId: currentInstance.projectId,
              targetProjectId: currentInstance.targetProjectId,
              folderUrn: currentInstance.folderUrn,
            });
          }
        });

        const productInstanceList: ProductInstanceAccBridgeMetadata[] = Array.from(uniqueProducts, ([, value]) => value);

        // Fetch all products from projects
        const productReleasesResponse = await getAllProductReleases(productInstanceList);

        //If there are no products, return
        if (!productReleasesResponse || productReleasesResponse.results.length === 0) {
          logAndShowNotification({
            message: productStoreText.noProductsAvailable,
          });
          return;
        }

        const { results: releasesAcrossAllProductsList, errors: productReleasesErrors } = productReleasesResponse;

        // Check if errors were produced while querying
        if (productReleasesErrors.length > 0) {
          logAndShowNotification({
            severity: 'warning',
            message: productStoreText.failedToFetchProducts,
          });
        }

        //Fetch all variants from products
        const variantsResponse = await batchFetchVariants(productInstanceList, false);

        //If there are no variants available, show a warning
        if (!variantsResponse || variantsResponse.results.length === 0) {
          logAndShowNotification({
            severity: 'warning',
            message: productStoreText.failedToFetchVariants,
          });
          return;
        }

        const { results: variantsList, errors: variantsErrors } = variantsResponse;

        // Check if errors were produced while querying
        if (variantsErrors.length > 0) {
          logAndShowNotification({
            severity: 'warning',
            message: productStoreText.failedToFetchVariants,
          });
        }

        // Filter variants to get the one's relevant to the model
        const filteredVariantsList = variantsList.filter((variant) =>
          instances.some((instance) => instance.variantId === variant.variantId),
        );

        // Filter the products based on filteredVariantsList using contentId and release
        const selectedProductReleases = releasesAcrossAllProductsList.filter((productRelease) =>
          filteredVariantsList.some(
            (variant) =>
              productRelease.release === variant.release &&
              productRelease.contentId === variant.contentId &&
              productRelease.status !== ReleaseStatus.OBSOLETE,
          ),
        );

        // Fetch rules for the selected productReleases
        const productReleasesWithRules = await Promise.all(
          selectedProductReleases.map(async (productRelease) => {
            const productInstance = uniqueProducts.get(productRelease.contentId);
            const incomingAccBridgeData = productInstance && getBridgeDataFromInstance(productInstance);

            if (productRelease.rulesKey && !productRelease.rules) {
              try {
                const rules = await ProductsUtils.checkAndDownloadProductRulesFromKey({
                  tenancyId: productRelease.tenancyId,
                  rulesKey: productRelease.rulesKey,
                  incomingAccBridgeData,
                });
                return { ...productRelease, rules };
              } catch (err) {
                logError(productStoreText.failedToFetchRules, err);
              }
            }
            return productRelease;
          }),
        );

        // Set new products with rules
        setProductReleases(productReleasesWithRules);

        // Set selected product release from local storage, if available
        const selectedProductRelease = getSelectedProductReleaseFromLocalStorage(productReleasesWithRules);
        if (selectedProductRelease) {
          setSelectedProductRelease(selectedProductRelease);
        }

        // Set variants
        setVariants(filteredVariantsList);
      } catch (err) {
        resetProductStoreState();
        logAndShowNotification({
          message: productStoreText.failedToFetchAssociateProducts,
        });
      } finally {
        setProductsLoading(false);
      }
    }
  }, [instances, logAndShowNotification, resetProductStoreState]);

  useEffect(() => {
    if (!instances) {
      return;
    }

    // Update instances with corresponding release number based from variants
    const updatedInstances = instances.map((instance) => {
      const variant = variants.find((variant) => variant.variantId === instance.variantId);
      if (variant) {
        return {
          ...instance,
          release: variant.release,
        };
      }
      return instance;
    });
    if (isEqual(instances, updatedInstances)) {
      return;
    }
    setInstances(updatedInstances);
  }, [instances, variants]);

  useEffect(() => {
    if (isUndefined(selectedProductRelease) && productReleases.length) {
      handleProductReleaseSelection(productReleases[0]);
    }
  }, [selectedProductRelease, productReleases, handleProductReleaseSelection]);

  useEffect(() => {
    fetchProductsData();
  }, [fetchProductsData]);

  return {
    variants,
    productReleases,
    productsLoading,
    selectedInstances,
    setSelectedInstances,
    dataGridInstances,
    setDataGridInstances,
    instances,
    setInstances,
    selectedProductRelease,
    handleProductReleaseSelection,
    resetProductStoreState,
  };
};
