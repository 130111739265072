import ProductContext from 'context/ProductStore/Product.context';
import useRFOModalStore from 'context/RFOModalStore/useRFOModalStore';
import { useContext, useEffect } from 'react';
import { useShallow } from 'zustand/react/shallow';
import { OutputSelectionPage, useOutputSelectionStore } from '@mid-react-common/common';

const FileTypesStep: React.FC = () => {
  const { selectedProductRelease, selectedInstances } = useContext(ProductContext);
  const { localSelectedProductRelease, setLocalSelectedProductRelease } = useRFOModalStore(
    useShallow((state) => ({
      localSelectedProductRelease: state.selectedProductRelease,
      setLocalSelectedProductRelease: state.setSelectedProductRelease,
    })),
  );

  const resetAllOutputs = useOutputSelectionStore((state) => state.resetAllOutputs);

  // We need to reset all outputs when the selected product release changes
  // because outputs can be different for between product releases
  useEffect(() => {
    if (
      selectedProductRelease?.contentId !== localSelectedProductRelease?.contentId ||
      selectedProductRelease?.release !== localSelectedProductRelease?.release
    ) {
      resetAllOutputs();
      setLocalSelectedProductRelease(selectedProductRelease);
    }
  }, [localSelectedProductRelease, resetAllOutputs, selectedProductRelease, setLocalSelectedProductRelease]);

  return <OutputSelectionPage selectedProductRelease={selectedProductRelease} selectedInstances={selectedInstances} />;
};

export default FileTypesStep;
