import { styled } from '@mui/material/styles';

export const OutputGenerationContainer = styled('div')`
  padding: 0 ${({ theme }) => theme.var.marginBase * 2}px;
`;

export const OutputGenerationFooterContainer = styled('div')`
  padding: 0 ${({ theme }) => theme.var.marginBase * 2}px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
