import { DCNeutralOutputs, OutputTypeWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';
import { SelectChangeEvent } from '@mui/material/Select';
import { handleModelStateChange, handleModelStateDelete } from '../OutputSelectionPage.utils';

interface UseNeutralFormatProps {
  outputsTypeNeutralFormat: DCNeutralOutputs[];
  satOutputs: string[];
  setSatOutputs: (satOutputs: string[]) => void;
  stepOutputs: string[];
  setStepOutputs: (stepOutputs: string[]) => void;
  glbOutputs: string[];
  setGlbOutputs: (glbOutputs: string[]) => void;
  allOutputs: PostVariantOutputWithVirtualTypes[];
  setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => void;
}

export interface UseNeutralFormatState {
  handleSatOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleStepOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleGlbOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleSatOutputDelete: (event: any, value: string) => void;
  handleStepOutputDelete: (event: any, value: string) => void;
  handleGlbOutputDelete: (event: any, value: string) => void;
}

const useNeutralFormat = ({
  outputsTypeNeutralFormat,
  allOutputs,
  glbOutputs,
  satOutputs,
  stepOutputs,
  setAllOutputs,
  setGlbOutputs,
  setSatOutputs,
  setStepOutputs,
}: UseNeutralFormatProps): UseNeutralFormatState => {
  const handleSatOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.SAT, satOutputs, allOutputs, setSatOutputs, setAllOutputs);
  };

  const handleStepOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.STEP, stepOutputs, allOutputs, setStepOutputs, setAllOutputs);
  };

  const handleGlbOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.GLB, glbOutputs, allOutputs, setGlbOutputs, setAllOutputs);
  };

  const handleSatOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.SAT,
      satOutputs,
      outputsTypeNeutralFormat,
      allOutputs,
      setSatOutputs,
      setAllOutputs,
    );
  };

  const handleStepOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.STEP,
      stepOutputs,
      outputsTypeNeutralFormat,
      allOutputs,
      setStepOutputs,
      setAllOutputs,
    );
  };

  const handleGlbOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.GLB,
      glbOutputs,
      outputsTypeNeutralFormat,
      allOutputs,
      setGlbOutputs,
      setAllOutputs,
    );
  };

  return {
    handleSatOutputsChange,
    handleGlbOutputsChange,
    handleStepOutputsChange,
    handleSatOutputDelete,
    handleStepOutputDelete,
    handleGlbOutputDelete,
  };
};

export default useNeutralFormat;
