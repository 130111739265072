import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Spacing } from '@mid-react-common/common';
import useDrawing2D from './useDrawing2D';
import { OutputType } from '@adsk/offsite-dc-sdk';
import Drawing2DDropdown from './Drawing2DDropwdown';
import { Section } from '../OutputSelectionPage.styles';
import testIds from '../../../tests/helpers/dataTestIds';
import { DCDrawingOutputs } from 'mid-types';
import { useOutputSelectionStore } from '../OutputSelectionContext/OutputSelectionContext';
import text from '../../../common.text.json';

const outputSelectionText = text.outputSelection;

interface Drawing2DProps {
  outputsTypeDrawing: DCDrawingOutputs[];
}

const Drawing2D: React.FC<Drawing2DProps> = ({ outputsTypeDrawing }) => {
  const { allOutputs, dwgOutputs, idwOutputs, pdfOutputs, setAllOutputs, setDwgOutputs, setIdwOutputs, setPdfOutputs } =
    useOutputSelectionStore((state) => ({
      allOutputs: state.allOutputs,
      dwgOutputs: state.dwgOutputs,
      idwOutputs: state.idwOutputs,
      pdfOutputs: state.pdfOutputs,
      setAllOutputs: state.setAllOutputs,
      setDwgOutputs: state.setDwgOutputs,
      setIdwOutputs: state.setIdwOutputs,
      setPdfOutputs: state.setPdfOutputs,
    }));

  const {
    drawingOutputsByType,
    handleIdwOutputsChange,
    handleDwgOutputsChange,
    handlePdfOutputsChange,
    handleDwgOutputDelete,
    handleIdwOutputDelete,
    handlePdfOutputDelete,
  } = useDrawing2D({
    outputsTypeDrawing,
    allOutputs,
    dwgOutputs,
    idwOutputs,
    pdfOutputs,
    setAllOutputs,
    setDwgOutputs,
    setIdwOutputs,
    setPdfOutputs,
  });

  return (
    <>
      {drawingOutputsByType.length > 0 && (
        <Section data-testid={testIds.outputSelection.drawing2DSection}>
          <Typography fontWeight="bold" variant="h3">
            {outputSelectionText.drawing2d}
          </Typography>
          <Spacing />
          <Box>
            {drawingOutputsByType.map((outputsByType, index) => {
              switch (outputsByType.type) {
                case OutputType.IDW:
                  return (
                    <Drawing2DDropdown
                      key={`${outputsByType.type}-${index}`}
                      label={outputSelectionText.inventorNative}
                      drawings={outputsByType.drawings}
                      selectedDrawings={idwOutputs}
                      handleOnChange={handleIdwOutputsChange}
                      handleDelete={handleIdwOutputDelete}
                    />
                  );
                case OutputType.DWG:
                  return (
                    <Drawing2DDropdown
                      key={`${outputsByType.type}-${index}`}
                      label={outputSelectionText.autocadNative}
                      drawings={outputsByType.drawings}
                      selectedDrawings={dwgOutputs}
                      handleOnChange={handleDwgOutputsChange}
                      handleDelete={handleDwgOutputDelete}
                    />
                  );
                case OutputType.PDF:
                  return (
                    <Drawing2DDropdown
                      key={`${outputsByType.type}-${index}`}
                      label={outputSelectionText.neutralPDF}
                      drawings={outputsByType.drawings}
                      selectedDrawings={pdfOutputs}
                      handleOnChange={handlePdfOutputsChange}
                      handleDelete={handlePdfOutputDelete}
                    />
                  );
              }
            })}
          </Box>
        </Section>
      )}
    </>
  );
};

export default Drawing2D;
