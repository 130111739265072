import { OutputTypeWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';

interface UseModel3DProps {
  setIsIamSelected: (isIamSelected: boolean) => void;
  allOutputs: PostVariantOutputWithVirtualTypes[];
  setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => void;
}

export interface UseModel3DState {
  handleInventorDataSetChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
}

const useModel3D = ({ setIsIamSelected, allOutputs, setAllOutputs }: UseModel3DProps): UseModel3DState => {
  const handleInventorDataSetChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const otherOutputs = allOutputs.filter((output) => output.type !== OutputTypeWithVirtualTypes.IAM);

    setIsIamSelected(checked);
    if (checked) {
      setAllOutputs([
        ...otherOutputs,
        {
          type: OutputTypeWithVirtualTypes.IAM,
        },
      ]);
    } else {
      setAllOutputs([...otherOutputs]);
    }
  };

  return {
    handleInventorDataSetChange,
  };
};

export default useModel3D;
