import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import text from '../../../../../global/text.json';
import useRFOModalStore from '../../../../../context/RFOModalStore/useRFOModalStore';
import { useShallow } from 'zustand/react/shallow';
import { useEffect } from 'react';
import { StepIconProps } from '@mui/material/StepIcon';
import { RFOCompletedIcon, RFOErrorIcon, RFOStepIconRoot, StepperConnector, StepperLabel } from './RFOStepper.styles';

import { rfoModalTestIds } from 'tests/helpers/dataTestIds';
import { useOutputSelectionStore } from '@mid-react-common/common';

const rfoModalText = text.rfoModal;

interface RFOModalStepperProps {
  activeStep: number;
}

const RFOStepIcon = (props: StepIconProps) => {
  const { active, completed, className, error } = props;

  return (
    <RFOStepIconRoot ownerState={{ active, error, completed }} className={className}>
      {completed && <RFOCompletedIcon data-testid={rfoModalTestIds.stepper.completedIcon} />}
      {error && <RFOErrorIcon data-testid={rfoModalTestIds.stepper.errorIcon} />}
    </RFOStepIconRoot>
  );
};

export const RFOStepper: React.FC<RFOModalStepperProps> = ({ activeStep }) => {
  const {
    isFileTypesStepCompleted,
    setIsFileTypesStepCompleted,
    uploadFolderUrn,
    setIsUploadLocationStepCompleted,
    isUploadLocationStepCompleted,
    hasCheckoutError,
    editPermissions,
    isCheckoutValidationCompleted,
    setIsCheckoutValidationCompleted,
  } = useRFOModalStore(
    useShallow((state) => ({
      isFileTypesStepCompleted: state.isFileTypesStepCompleted,
      setIsFileTypesStepCompleted: state.setIsFileTypesStepCompleted,
      isUploadLocationStepCompleted: state.isUploadLocationStepCompleted,
      setIsUploadLocationStepCompleted: state.setIsUploadLocationStepCompleted,
      isCheckoutValidationCompleted: state.isCheckoutValidationCompleted,
      setIsCheckoutValidationCompleted: state.setIsCheckoutValidationCompleted,
      editPermissions: state.editPermissions,
      uploadFolderUrn: state.uploadFolderUrn,
      hasCheckoutError: state.hasCheckoutError,
    })),
  );

  const allOutputs = useOutputSelectionStore((state) => state.allOutputs);

  // Check if the file types step is completed
  useEffect(() => {
    if (allOutputs.length > 0) {
      setIsFileTypesStepCompleted(true);
    } else {
      setIsFileTypesStepCompleted(false);
    }
  }, [allOutputs, setIsFileTypesStepCompleted]);

  //Check if the upload location step is completed
  //Urn needs to be set and it must have edit permission
  useEffect(() => {
    if (uploadFolderUrn && editPermissions[uploadFolderUrn]) {
      setIsUploadLocationStepCompleted(true);
    } else {
      setIsUploadLocationStepCompleted(false);
    }
  }, [setIsUploadLocationStepCompleted, uploadFolderUrn, editPermissions]);

  // If any outputs have changed mark the checkout step as incomplete
  useEffect(() => {
    setIsCheckoutValidationCompleted(false);
  }, [allOutputs, setIsCheckoutValidationCompleted]);

  return (
    <Stepper nonLinear activeStep={activeStep} alternativeLabel connector={<StepperConnector />}>
      <Step completed={isFileTypesStepCompleted} data-testid={rfoModalTestIds.stepper.fileTypesStep}>
        <StepperLabel StepIconComponent={RFOStepIcon}>{rfoModalText.fileTypes}</StepperLabel>
      </Step>
      <Step completed={isUploadLocationStepCompleted} data-testid={rfoModalTestIds.stepper.uploadLocationStep}>
        <StepperLabel StepIconComponent={RFOStepIcon}>{rfoModalText.uploadLocation}</StepperLabel>
      </Step>
      <Step
        completed={isFileTypesStepCompleted && isUploadLocationStepCompleted && isCheckoutValidationCompleted}
        data-testid={rfoModalTestIds.stepper.checkoutStep}
      >
        <StepperLabel StepIconComponent={RFOStepIcon} error={hasCheckoutError}>
          {rfoModalText.checkout}
        </StepperLabel>
      </Step>
    </Stepper>
  );
};
