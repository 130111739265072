import { create } from 'zustand';
import { ProductRelease } from '@adsk/offsite-dc-sdk';
import { UploadLocationStepSlice, createUploadLocationStepSlice } from './UploadLocationStepSlice';
import { createStepperSlice, StepperSlice, stepsMap } from './StepperSlice';
import { CheckoutStepSlice, createCheckoutStepSlice } from './CheckoutStepSlice';

export interface RFOModalStore extends UploadLocationStepSlice, StepperSlice, CheckoutStepSlice {
  selectedProductRelease: ProductRelease | undefined;
  setSelectedProductRelease: (selectedProductRelease: ProductRelease | undefined) => void;
  resetModal: () => void;
}

const useRFOModalStore = create<RFOModalStore>((set, get, api) => ({
  selectedProductRelease: undefined,
  setSelectedProductRelease: (selectedProductRelease: ProductRelease | undefined) => set({ selectedProductRelease }),
  resetModal: () =>
    set({ activeStep: stepsMap.fileTypes, isCheckoutValidationCompleted: false, isUploadLocationStepCompleted: false }),
  ...createUploadLocationStepSlice(set, get, api),
  ...createCheckoutStepSlice(set, get, api),
  ...createStepperSlice(set, get, api),
}));

export default useRFOModalStore;
