import { DCBomOutputs, OutputTypeWithVirtualTypes, PostVariantOutputWithVirtualTypes } from 'mid-types';
import { SelectChangeEvent } from '@mui/material/Select';
import { handleModelStateChange, handleModelStateDelete } from '../OutputSelectionPage.utils';
import { useEffect } from 'react';

interface UseBillOfMaterialsProps {
  outputsTypeBom: DCBomOutputs[];
  bomOutputs: string[];
  setBomOutputs: (bomOutputs: string[]) => void;
  aggregatedBomOutputs: string[];
  setAggregatedBomOutputs: (aggregatedBomOutputs: string[]) => void;
  allOutputs: PostVariantOutputWithVirtualTypes[];
  setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => void;
}

interface UseBillOfMaterialsState {
  handleBomOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleAggregatedBomOutputsChange: (event: SelectChangeEvent<string[]>) => void;
  handleBomOutputDelete: (event: any, value: string) => void;
  handleAggregatedBomOutputDelete: (event: any, value: string) => void;
}

const useBillOfMaterials = ({
  outputsTypeBom,
  aggregatedBomOutputs,
  allOutputs,
  bomOutputs,
  setAggregatedBomOutputs,
  setAllOutputs,
  setBomOutputs,
}: UseBillOfMaterialsProps): UseBillOfMaterialsState => {
  // Need to clear the selected aggregated boms whenever it no longer applies
  useEffect(() => {
    const hasAggregatedBomOutputs = !!outputsTypeBom.find(
      (outputByType) => outputByType.type === OutputTypeWithVirtualTypes.BOMAGGREGATED,
    );
    const otherOutputs = allOutputs.filter((output) => output.type !== OutputTypeWithVirtualTypes.BOMAGGREGATED);
    if (aggregatedBomOutputs.length && !hasAggregatedBomOutputs) {
      setAggregatedBomOutputs([]);
      setAllOutputs(otherOutputs);
    }
  }, [aggregatedBomOutputs.length, allOutputs, outputsTypeBom, setAggregatedBomOutputs, setAllOutputs]);

  const handleBomOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(value, OutputTypeWithVirtualTypes.BOM, bomOutputs, allOutputs, setBomOutputs, setAllOutputs);
  };

  const handleAggregatedBomOutputDelete = (event: any, value: string) => {
    handleModelStateDelete(
      value,
      OutputTypeWithVirtualTypes.BOMAGGREGATED,
      aggregatedBomOutputs,
      allOutputs,
      setAggregatedBomOutputs,
      setAllOutputs,
    );
  };

  const handleBomOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.BOM,
      bomOutputs,
      outputsTypeBom,
      allOutputs,
      setBomOutputs,
      setAllOutputs,
    );
  };

  const handleAggregatedBomOutputsChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    handleModelStateChange(
      value,
      OutputTypeWithVirtualTypes.BOMAGGREGATED,
      aggregatedBomOutputs,
      outputsTypeBom,
      allOutputs,
      setAggregatedBomOutputs,
      setAllOutputs,
    );
  };

  return {
    handleAggregatedBomOutputsChange,
    handleBomOutputsChange,
    handleAggregatedBomOutputDelete,
    handleBomOutputDelete,
  };
};

export default useBillOfMaterials;
