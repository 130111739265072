import { createContext, PropsWithChildren, useContext, useRef } from 'react';
import { StoreApi } from 'zustand';
import { useStoreWithEqualityFn } from 'zustand/traditional';
import { shallow } from 'zustand/vanilla/shallow';
import { createOutputTypesStore, OutputTypesProps, OutputTypesSlice } from './OutputTypesSlice';

type OutputSelectionProviderProps = PropsWithChildren<Partial<OutputTypesProps>>;

export const OutputSelectionContext = createContext<StoreApi<OutputTypesSlice>>({} as StoreApi<OutputTypesSlice>);

export const OutputSelectionContextProvider: React.FC<OutputSelectionProviderProps> = ({ children, ...storeProps }) => {
  const storeRef = useRef<StoreApi<OutputTypesSlice>>(createOutputTypesStore(storeProps));
  if (!storeRef.current) {
    storeRef.current = createOutputTypesStore(storeProps);
  }

  return <OutputSelectionContext.Provider value={storeRef.current}>{children}</OutputSelectionContext.Provider>;
};

export const useOutputSelectionStore = <T,>(selector: (state: OutputTypesSlice) => T): T => {
  const store = useContext(OutputSelectionContext);
  if (!store) {
    throw Error('Missing OutputSelectionContext.Provider in the tree');
  }

  return useStoreWithEqualityFn(store, selector, shallow);
};
