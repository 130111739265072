import {
  OutputStatuses,
  OutputStatus,
  GetUploadOutputsRequestResponse,
  UploadOutputStatus,
  ProductRelease,
  Variant,
  OutputTypes,
  OutputType,
} from '@adsk/offsite-dc-sdk';
import { format } from 'date-fns';
import { flatten } from 'lodash';
import { getDcApiServiceInstance } from 'mid-api-services';
import {
  getDCOutputDrawingPath,
  getDCOutputModelStates,
  getUUID,
  getVariantOutputModelState,
  getVariantOutputsDrawingPath,
  isFulfilled,
} from 'mid-utils';
import { Instance } from 'types/product';
import { OutputsReviewTableDataModel, OutputsReviewMap, OutputStatusMetadata, OutputUploadRequestMap } from './Types';
import text from '../../../global/text.json';
import { VariantInstanceAccBridgeMetadata } from 'types/bridge';
import { getBridgeAutomationFromVariantId } from 'utils/bridge';
import { AccBridgeSourceProjectDataQueryParams } from 'mid-types';

export const VARIANT_OUTPUT_UPLOAD_STATUS_POLLING_INTERVAL = 5000;

const reviewPanelText = text.reviewPanel;
const LEFT_SQUAREBRACKET = '[';
const RIGHT_SQUAREBRACKET = ']';

export const UPLOAD_TIMESTAMP_FORMAT = 'MMM d, yyyy hh:mm a';

const isVariantOutputPollingRequired = (status: OutputStatuses | undefined) =>
  !(status === undefined || OutputStatus.SUCCESS === status || OutputStatus.FAILED === status);

export const shouldVariantOutputsPollingContinue = (variantsOutputs: OutputsReviewTableDataModel[]): boolean =>
  variantsOutputs.some((variantOutputs) => isVariantOutputPollingRequired(variantOutputs.status));

export const shouldVariantOutputsUploadStatusPollingContinue = (
  allUploadOutputsRequestResponses: GetUploadOutputsRequestResponse[],
): boolean => {
  // Naive polling: We continue polling if even a single
  // requested output is not in a finished state (success, wont_upload or failed)
  const pendingUploadRequestFound = allUploadOutputsRequestResponses.some((getUploadOutputsRequestResponse) =>
    getUploadOutputsRequestResponse.requestedOutputs.some(
      (outputUploadInfo) =>
        outputUploadInfo.result.uploadStatus !== UploadOutputStatus.SUCCESS &&
        outputUploadInfo.result.uploadStatus !== UploadOutputStatus.WONT_UPLOAD &&
        outputUploadInfo.result.uploadStatus !== UploadOutputStatus.FAILED,
    ),
  );

  return pendingUploadRequestFound;
};

export const transformInstancesToOutputsReviewMap = (
  instances: Instance[],
  selectedProductRelease: ProductRelease,
): OutputsReviewMap => {
  const instancesFromCurrentRelease = instances.filter(
    (instance) =>
      instance.release === selectedProductRelease.release && instance.contentId === selectedProductRelease.contentId,
  );
  return instancesFromCurrentRelease.reduce((prev: OutputsReviewMap, current: Instance): OutputsReviewMap => {
    if (!prev[current.variantId]) {
      const allOutputStatuses: OutputsReviewTableDataModel[] = [];
      selectedProductRelease?.outputs.forEach((output) => {
        if (!shouldOutputBeIncluded(output.type)) {
          return;
        }

        const outputStatusProps: OutputsReviewTableDataModel = {
          id: '',
          contentId: current.contentId,
          fileType: output.type,
          status: undefined,
          name: current.variantName,
          productName: selectedProductRelease.name,
          modifiedAt: '',
          variantId: current.variantId,
          modelState: undefined,
          drawingPath: getDCOutputDrawingPath(output),
          elementId: current.elementId,
          instanceList: [current.elementId],
          release: current.release || selectedProductRelease.release,
          outputUploadStatus: null,
          outputLocation: null,
          outputLocationAccountId: null,
          outputLocationProjectId: null,
          outputProjectVendor: null,
        };

        const modelStates = getDCOutputModelStates(output);
        if (modelStates) {
          allOutputStatuses.push(
            ...modelStates.map((modelState) => ({
              ...outputStatusProps,
              id: getUUID(), //uuid to prevent collisions
              modelState,
            })),
          );
        } else {
          allOutputStatuses.push({
            ...outputStatusProps,
            id: getUUID(), //uuid to prevent collisions
          });
        }
      });
      prev[current.variantId] = allOutputStatuses;
    } else {
      prev[current.variantId] = prev[current.variantId].map((tableData) => ({
        ...tableData,
        instanceList: [...tableData.instanceList, current.elementId],
      }));
    }
    return prev;
  }, {});
};

export const getVariantOutputs = async ({
  projectId,
  productId,
  variantId,
  incomingAccBridgeData,
}: {
  projectId: string;
  productId: string;
  variantId: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
}): Promise<OutputStatusMetadata[]> => {
  // Fetch output status
  const variant = await getDcApiServiceInstance().getVariant({
    projectId,
    productId,
    variantId,
    incomingAccBridgeData,
  });

  const variantsWithFilteredOutput = filterVariantOutputs(variant);

  return variantsWithFilteredOutput.map((output) => ({
    status: output?.status,
    modifiedAt: format(new Date(variant.updatedAt), UPLOAD_TIMESTAMP_FORMAT),
    objectKey: output?.urn,
    fileType: output.type,
    variantId,
    modelState: getVariantOutputModelState(output),
    drawingPath: getVariantOutputsDrawingPath(output),
  }));
};

const filterVariantOutputs = (variant: Variant) => variant.outputs.filter((output) => shouldOutputBeIncluded(output.type));

const shouldOutputBeIncluded = (outputType: OutputTypes) =>
  outputType !== OutputType.THUMBNAIL && outputType !== OutputType.RFA;

export const transformModelState = (modelState: string | undefined): string | undefined =>
  !modelState
    ? reviewPanelText.unspecifiedRepresentation
    : modelState.startsWith(LEFT_SQUAREBRACKET) && modelState.endsWith(RIGHT_SQUAREBRACKET)
      ? modelState.slice(1, modelState.length - 1)
      : modelState;

export const aggregateUploadResultsPerOutputAndReturnLatest = (
  getUploadOutputsRequestResponses: GetUploadOutputsRequestResponse[],
): OutputUploadRequestMap =>
  getUploadOutputsRequestResponses.reduce<OutputUploadRequestMap>((aggregatedMap, uploadRequest) => {
    uploadRequest.requestedOutputs.forEach((output) => {
      if (!output.result.updatedAt) {
        return;
      }
      const uniqueIdentifierForOutputUploadRequest = getUniqueIdentifierForOutputUploadRequestAggregation(
        uploadRequest.contentId,
        uploadRequest.variantId,
        output.type,
        output.modelState,
        output.drawingTemplatePath,
      );

      if (
        !(uniqueIdentifierForOutputUploadRequest && uniqueIdentifierForOutputUploadRequest in aggregatedMap) ||
        new Date(output.result.updatedAt) > new Date(aggregatedMap[uniqueIdentifierForOutputUploadRequest].updatedAt)
      ) {
        // Product Team Decision: The status of the latest upload request for an
        // output (e.g., [Primary] model state + .csv)
        // takes precedence over all previous requests
        // even if the latest request is in any non-success state e.g., pending/processing/wont_upload/failed
        // or was requested for a different upload location
        aggregatedMap[uniqueIdentifierForOutputUploadRequest] = {
          updatedAt: output.result.updatedAt,
          outputUploadStatus: output.result.uploadStatus,
          outputLocation: uploadRequest.outputLocations[output.type]?.outputLocation || null,
          accountId: uploadRequest.outputLocations[output.type]?.accountId || null,
          projectId: uploadRequest.outputLocations[output.type]?.projectId || null,
          outputProjectVendor: uploadRequest.outputLocations[output.type]?.vendor || null,
        };
      }
    });
    return aggregatedMap;
  }, {});

export const getOutputUploadRequestsForAllVariants = async ({
  transformedInstances,
  selectedContentId,
  projectId,
  variantAccBridgeMap,
  signal,
}: {
  transformedInstances: OutputsReviewMap | null;
  selectedContentId: string;
  projectId?: string;
  variantAccBridgeMap?: Map<string, VariantInstanceAccBridgeMetadata>;
  signal: AbortSignal;
}): Promise<GetUploadOutputsRequestResponse[]> => {
  if (transformedInstances && selectedContentId && projectId) {
    const getUploadRequests: Promise<GetUploadOutputsRequestResponse[]>[] = Object.keys(transformedInstances).map(
      (variantId) => {
        const accBridgeData = getBridgeAutomationFromVariantId(variantId, variantAccBridgeMap);

        return getDcApiServiceInstance().getVariantOutputUploadRequests({
          projectId,
          productId: selectedContentId,
          variantId,
          incomingAccBridgeData: accBridgeData,
          signal,
        });
      },
    );

    const allSettledOutcomes = await Promise.allSettled(getUploadRequests);

    const succeededUploadRequests = allSettledOutcomes.filter(isFulfilled);
    const succeededUploadRequestValues = succeededUploadRequests.map((request) => request.value);

    return flatten(succeededUploadRequestValues);
  }
  return [];
};

export const getUniqueIdentifierForOutputUploadRequestAggregation = (
  contentId: string,
  variantId: string,
  outputType: OutputTypes,
  modelState: string | undefined,
  drawingTemplatePath: string | undefined,
): string => {
  // We cannot use the output's fileName or URN as unique identifiers for aggregation,
  // as they are not immediately available after the variant output generation (POST variant) is successful
  // The values can be empty strings during polling for outputUploadResults initially. To circumvent
  // this, we build our own uniqueId that can be computed using data from both GET Variant & GET VariantUploadResults
  let uniqueIdentifier = `${contentId}:${variantId}:${outputType}`;

  if (modelState) {
    uniqueIdentifier += `:${transformModelState(modelState)}`;
  }
  if (drawingTemplatePath) {
    uniqueIdentifier += `:${drawingTemplatePath}`;
  }

  return uniqueIdentifier;
};
