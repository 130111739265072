import { DCInput, DCInputBase, InputType, VariantInput } from '@adsk/offsite-dc-sdk';
import { Buffer } from 'buffer';
import { uniqBy } from 'lodash';
import { DCRules, ENVIRONMENT, Environment, FormRules, FormRulesInput, InputRule } from 'mid-types';
import { parse } from 'uuid';
import { logError } from '../errors/logging';
import { isFormRulesGroup } from '../typeGuards';

export const getReactAppEnvironment = (): Environment => {
  const env = import.meta.env.VITE_ENVIRONMENT as Environment;

  return env || ENVIRONMENT.MOCK;
};

export const removeDoubleQuotesFromStringArray = (array: string[]): string[] =>
  array.map((value) => removeDoubleQuotes(value));
export const removeDoubleQuotes = (value: string): string => value.replace(/^"(.*)"$/, '$1');
export const addDoubleQuotesToStringArray = (array: string[]): string[] => array.map((value) => addDoubleQuotes(value));
export const addDoubleQuotes = (value: string): string => (value.startsWith('"') ? value : `"${value}"`);
export const addQuotesToTextParametersObject = <Input extends DCInput>(
  parameters: Record<string, Input>,
): Record<string, Input> => {
  for (const [key, parameter] of Object.entries(parameters)) {
    if (parameter.type === InputType.TEXT) {
      const unTrimmedValue = addDoubleQuotes(parameter.value);
      const unTrimmedArray = parameter.values ? addDoubleQuotesToStringArray(parameter.values) : parameter.values;
      parameters[key] = { ...parameter, value: unTrimmedValue, values: unTrimmedArray };
    }
  }

  return parameters;
};

const removeQuotes = <Input extends DCInput | VariantInput>(parameter: Input): Input => {
  if (parameter.type === InputType.TEXT) {
    const trimmedValue = removeDoubleQuotes(parameter.value);
    if ('values' in parameter) {
      const trimmedArray = parameter.values ? removeDoubleQuotesFromStringArray(parameter.values) : parameter.values;
      return { ...parameter, value: trimmedValue, values: trimmedArray };
    }
    return { ...parameter, value: trimmedValue };
  }

  return parameter;
};

export const removeQuotesFromTextParametersInArray = <Input extends DCInput | VariantInput>(parameters: Input[]): Input[] =>
  parameters.map(removeQuotes);

export const applyFormRulesToInputs = <T extends DCInputBase>({
  formRules,
  productReleaseInputs,
}: {
  formRules: string | undefined;
  productReleaseInputs: T[];
}): T[] => {
  let formRulesObject: FormRules | undefined;

  try {
    if (formRules) {
      formRulesObject = JSON.parse(formRules);
    }
  } catch (error) {
    logError(error);
  }

  if (formRulesObject) {
    // Flatten the form rules object to get the inputs to display
    let inputsInTab: FormRulesInput[] = [];
    let inputsInRootForm: FormRulesInput[] = [];
    if (formRulesObject.tabs) {
      inputsInTab = formRulesObject.tabs.reduce<FormRulesInput[]>((prevInputs, currentTab) => {
        if (currentTab.inputs) {
          const inputsInCurrentTab = currentTab.inputs.reduce<FormRulesInput[]>((acc, input) => {
            if (isFormRulesGroup(input)) {
              acc = acc.concat(input.inputs);
            } else {
              acc.push(input);
            }
            return acc;
          }, []);
          prevInputs.push(...inputsInCurrentTab);
        }
        return prevInputs;
      }, []);
    }
    if (formRulesObject.inputs) {
      inputsInRootForm = formRulesObject.inputs.reduce<FormRulesInput[]>((acc, input) => {
        if (isFormRulesGroup(input)) {
          acc = acc.concat(input.inputs);
        } else {
          acc.push(input);
        }
        return acc;
      }, []);
    }

    // merge the inputs both from the root form and tabs with the inputs from the product release
    const mergedInputsAndRules = [...inputsInRootForm, ...inputsInTab].reduce<T[]>((acc, formInputs) => {
      const productInput = productReleaseInputs.find((input) => input.name === formInputs.name);
      if (productInput) {
        acc.push({ ...productInput, ...formInputs });
      }
      return acc;
    }, []);

    // Return the "unique" merged inputs
    return uniqBy(mergedInputsAndRules, 'name');
  }

  // Otherwise, we just return the product release inputs without labels
  // NOTE: the only way to set labels is through in the API is by input rules.
  // The requirement is that we don't show the labels set by input rules, only by form rules.
  const inputsWithUpdatedLabels = productReleaseInputs.map((input) =>
    input.label ? { ...input, label: undefined } : input,
  );

  // Check if the inputs are not applicable. If so, we should not show the value.
  return inputsWithUpdatedLabels.map((input) => {
    if (input.applicable === false) {
      return { ...input, value: '—' };
    }
    return input;
  });
};

export const getDefaultInputLabel = (input: DCInput): string => {
  if (input.type === InputType.NUMERIC) {
    return `${input.name} (${input.unit})`;
  }
  return `${input.name}`;
};

/* Convert short URN to Folder Urn
 */
export const shortUrn2FolderUrn = (shortUrn: string, currentEnv: Environment = getReactAppEnvironment()): string => {
  const env = currentEnv === ENVIRONMENT.PRD ? 'prod' : 'stg';

  return `urn:adsk.wip${env}:fs.folder:co.${shortUrn}`;
};

/* Convert GUID to folder urn
 * Example: 00000000-0000-1000-8000-00000000002a -> urn:adsk.wipstg:fs.folder:co.AAAAAAAAEACAAAAAAAAAKg
 */
export const guid2Urn = (guid: string, currentEnv: Environment = getReactAppEnvironment()): string => {
  const parsedGuid = parse(guid) as any;
  const urn = Buffer.from(parsedGuid).toString('base64').replace(/=+$/, '').replace(/\+/g, '-').replace(/\//g, '_');
  return shortUrn2FolderUrn(urn, currentEnv);
};

export const setDefaultInputValuesIfNotApplicable = <T extends DCInput | VariantInput>(
  inputs: T[],
  defaultInputs: T[],
): T[] =>
  inputs.map((input) => {
    const defaultInput = defaultInputs?.find((defaultInput) => defaultInput.name === input.name);
    if (defaultInput && input.applicable === false) {
      return { ...input, value: defaultInput.value };
    }
    return input;
  });

export const inputsRulesToDCRules = (rules: InputRule[]): DCRules =>
  rules.reduce<DCRules>(
    (templateRule, rule) => (
      (templateRule[rule.key] = { code: rule.code, errorMessage: rule.errorMessage, ruleLabel: rule.label }), templateRule
    ),
    {},
  );
