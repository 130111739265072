import {
  DynamicContent,
  ProductRelease,
  OutputTypes,
  OutputType,
  Variant,
  InputTypes,
  IPropertyInputType,
} from '@adsk/offsite-dc-sdk';
import { AccBridgeSourceProjectDataQueryParams, AccBridgeStatus } from './accBridgeApi';
import { DCInputIPropertyType } from '@adsk/offsite-dc-sdk';

export type DCProductUIExtension<T> = T & {
  rules?: DCRules;
  codeBlocksWorkspace?: string;
  accBridgeStatus?: AccBridgeStatus;
  isFromAccBridge?: boolean;
  targetFolderUrn?: string;
  incomingAccBridgeData?: AccBridgeSourceProjectDataQueryParams;
};

export interface DCRules {
  [key: string]: {
    ruleLabel?: string;
    errorMessage?: string;
    code: string;
  };
}

// TODO: Remove iProperty from DCInput once it is removed from the SDK
// https://jira.autodesk.com/browse/TRADES-6733
export type DCInputUIExtension<T> = Exclude<T, DCInputIPropertyType> & {
  error?: boolean;
  message?: string;
};
export interface BatchFetchProductsResult {
  results: DynamicContent[];
  errors: PromiseRejectedResult[];
}

export interface PaginationMeta {
  limit: number;
  nextUrl?: string;
}

export interface VariantsResponse {
  pagination: PaginationMeta;
  results: Variant[];
}

export const IDW_FILE_TYPE = 'idw';
export const DWG_FILE_TYPE = 'dwg';
export const PDF_FILE_TYPE = 'pdf';

export const drawingTemplateFileTypes = [IDW_FILE_TYPE, DWG_FILE_TYPE];

export const drawingOutputTypes: { [key: string]: OutputTypes } = {
  [IDW_FILE_TYPE]: OutputType.IDW,
  [DWG_FILE_TYPE]: OutputType.DWG,
  [PDF_FILE_TYPE]: OutputType.PDF,
};

export interface GetAllProductReleasesResult {
  results: DCProductUIExtension<ProductRelease>[];
  errors: PromiseRejectedResult[];
}

// TODO: Remove iProperty from DCInput once it is removed from the SDK
// https://jira.autodesk.com/browse/TRADES-6733
export type DCUIInputTypes = Exclude<InputTypes, IPropertyInputType>;

export const productDefinitionEngines = {
  INVENTOR: 'DA4I',
  FUSION: 'DA4F',
} as const;

export type ProductDefinitionEngine = (typeof productDefinitionEngines)[keyof typeof productDefinitionEngines];
