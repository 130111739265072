export default {
  modalCancelTestId: 'modal-cancel-button',
  modalConfirmTestId: 'modal-confirm-button',
  closeIcon: 'CloseIcon',
  expandableTextSection: {
    mainContent: 'expandable-main-content',
    expandCollapseContentButton: 'expand-collapse-content-button',
  },
  locationInfo: {
    uploadLocationAccountName: 'upload-location-account-name',
    uploadLocationProjectName: 'upload-location-project-name',
    uploadLocationFolderName: 'upload-location-folder-name',
    showHideBreadcrumbs: 'show-hide-breadcrumbs',
    folderIcon: 'folder-icon',
    lockedFolderIcon: 'locked-folder-icon',
    folderPathBreadcrumbs: 'folder-path-breadcrumbs',
    bim360ProjectIconType: 'upload-location-project-selector-bim360-icon-type',
    accProjectIconType: 'upload-location-project-selector-acc-icon-type',
    fusionProjectIconType: 'upload-location-project-selector-fusion-icon-type',
  },
  outputSelection: {
    model3DSection: 'model-3d-section',
    drawing2DSection: 'drawing-2d-section',
    bomSection: 'bom-section',
    neutralFormatSection: 'neutral-format-section',
    drawing2dDropdowns: 'drawing2d-dropdowns',
    neutralFormatDropdowns: 'neutral-format-dropdowns',
    bomDropdowns: 'bom-dropdowns',
    chipRemoveButton: 'chip-remove-button',
    allDrawingsMenuItem: 'all-drawings-menu-item',
    drawingOutputMenuItem: 'drawing-menu-item',
    drawingOutputChip: 'drawing-output-chip',
    allNeutralFormatMenuItem: 'all-neutral-format-menu-item',
    neutralFormatOutputMenuItem: 'neutral-format-menu-item',
    neutralFormatOutputChip: 'neutral-format-output-chip',
    allBomMenuItem: 'all-bom-menu-item',
    bomOutputMenuItem: 'bom-output-menu-item',
    bomOutputChip: 'bom-output-chip',
  },
};
