import React from 'react';

import CheckIcon from '@mui/icons-material/Check';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import QuestionMarkOutlined from '@mui/icons-material/QuestionMarkOutlined';

import { OutputStatus, UploadOutputStatus } from '@adsk/offsite-dc-sdk';
import { midTheme } from '@mid-react-common/common';
import CircularProgress from '@mui/material/CircularProgress';
import { GridRenderCellParams } from '@mui/x-data-grid';
import text from '../../../global/text.json';
import { ReviewPanelStatusCell } from './ReviewPanel.styles';
import { OutputsReviewTableDataModel } from './Types';

const reviewPanel = text.reviewPanel;
const StatusOutputsCell: React.FC<GridRenderCellParams<OutputsReviewTableDataModel>> = ({ value, row }) => {
  switch (row.outputUploadStatus) {
    case UploadOutputStatus.PENDING:
    case UploadOutputStatus.PROCESSING:
      return (
        <ReviewPanelStatusCell>
          <CircularProgress size={midTheme.var.buttonSpinnerSize} /> {reviewPanel.pending}
        </ReviewPanelStatusCell>
      );
    case UploadOutputStatus.SUCCESS:
      return (
        <ReviewPanelStatusCell>
          <CheckIcon color="success" /> {reviewPanel.success}
        </ReviewPanelStatusCell>
      );
    case UploadOutputStatus.FAILED:
    case UploadOutputStatus.WONT_UPLOAD:
      return (
        <ReviewPanelStatusCell>
          <DoDisturbIcon color="error" /> {reviewPanel.failed}
        </ReviewPanelStatusCell>
      );
  }

  switch (value) {
    case OutputStatus.PENDING:
      return (
        <ReviewPanelStatusCell>
          <CircularProgress size={midTheme.var.buttonSpinnerSize} /> {reviewPanel.pending}
        </ReviewPanelStatusCell>
      );
    case OutputStatus.SUCCESS:
      return (
        <ReviewPanelStatusCell>
          <CheckIcon color="success" /> {reviewPanel.success}
        </ReviewPanelStatusCell>
      );
    case OutputStatus.FAILED:
      return (
        <ReviewPanelStatusCell>
          <DoDisturbIcon color="error" /> {reviewPanel.failed}
        </ReviewPanelStatusCell>
      );
    default:
      return (
        <ReviewPanelStatusCell>
          <QuestionMarkOutlined /> {reviewPanel.notAvailable}
        </ReviewPanelStatusCell>
      );
  }
};

export default StatusOutputsCell;
