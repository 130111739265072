import { Variant } from '@adsk/offsite-dc-sdk';
import ProductContext from 'context/ProductStore/Product.context';
import { OutputTypeWithVirtualTypes } from 'mid-types';
import { useContext, useEffect } from 'react';
import { createTableData, generateTokenCost, TableRowData } from '../../utils';

interface UseOutputsTableProps {
  isIamSelected: boolean;
  aggregatedBomOutputs: string[];
  bomOutputs: string[];
  dwgOutputs: string[];
  glbOutputs: string[];
  idwOutputs: string[];
  pdfOutputs: string[];
  satOutputs: string[];
  stepOutputs: string[];
  setFileTypesStepActive: () => void;
  setTotalTokenCost: (totalTokenCost: number) => void;
}

interface UseOutputsTableState {
  tableData: TableRowData[];
  totalQuantity: number;
  totalCostInUnicodeValue: string;
  selectedVariants: Variant[];
  handleFileTypesChangeClick: () => void;
}

const useOutputsTable = ({
  isIamSelected,
  aggregatedBomOutputs,
  bomOutputs,
  dwgOutputs,
  glbOutputs,
  idwOutputs,
  pdfOutputs,
  satOutputs,
  stepOutputs,
  setFileTypesStepActive,
  setTotalTokenCost,
}: UseOutputsTableProps): UseOutputsTableState => {
  const { selectedInstances, variants } = useContext(ProductContext);

  const selectedVariants = variants.filter((variant) =>
    selectedInstances?.some((instance) => instance.variantId === variant.variantId),
  );

  const allOutputs = [
    { outputs: dwgOutputs, type: OutputTypeWithVirtualTypes.DWG },
    { outputs: idwOutputs, type: OutputTypeWithVirtualTypes.IDW },
    { outputs: pdfOutputs, type: OutputTypeWithVirtualTypes.PDF },
    { outputs: bomOutputs, type: OutputTypeWithVirtualTypes.BOM },
    { outputs: aggregatedBomOutputs, type: OutputTypeWithVirtualTypes.BOMAGGREGATED },
    { outputs: satOutputs, type: OutputTypeWithVirtualTypes.SAT },
    { outputs: glbOutputs, type: OutputTypeWithVirtualTypes.GLB },
    { outputs: stepOutputs, type: OutputTypeWithVirtualTypes.STEP },
  ];

  const tableData = createTableData(isIamSelected, allOutputs, selectedVariants);

  const totalQuantity = tableData.reduce((acc, output) => acc + output.fileTypeQuantity, 0);
  const { totalCost, totalCostInUnicodeValue } = generateTokenCost(totalQuantity);

  useEffect(() => {
    setTotalTokenCost(totalCost);
  }, [totalCost, setTotalTokenCost]);

  return {
    tableData,
    totalQuantity,
    totalCostInUnicodeValue,
    selectedVariants,
    handleFileTypesChangeClick: setFileTypesStepActive,
  };
};

export default useOutputsTable;
