export const extensionName = 'Autodesk.InformedDesign';
export const cssPrefix = 'ind.';

export const domIdSuffixes = {
  outputGenerationButton: 'outputGenerationButton',
  outputGenerationPanel: 'outputGenerationPanel',
} as const;

export const formPanelSuffixId = 'formPanel';
export const formPanelId = `${cssPrefix}${formPanelSuffixId}`;
export const dockingPanelFooterCSSClass = '.docking-panel-footer';
export const dockingPanelTitleCSSClass = '.docking-panel-title';
