import { NOTIFICATION_STATUSES, NotificationContext, useOutputSelectionStore } from '@mid-react-common/common';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import React, { useContext } from 'react';
import text from '../../text.json';
import { OutputGenerationFooterContainer } from './OutputGeneration.styles';

const OutputGenerationFooter: React.FC = () => {
  const theme = useTheme();
  const { showNotification } = useContext(NotificationContext);

  const allOutputs = useOutputSelectionStore((state) => state.allOutputs);
  const numberOfOutputs = allOutputs.length;

  const handleGenerateOutputs = () => {
    // TODO: Use variant Id to post outputs to variants

    showNotification({
      message: text.outputGeneration.requestSubmitted,
      messageBody: (
        <div>
          <div>{text.outputGeneration.generatingOutputsForVariant}</div>
          <Link>{text.outputGeneration.viewResults}</Link>
        </div>
      ),
      severity: NOTIFICATION_STATUSES.INFO,
      autoDismiss: true,
    });
  };

  return (
    <OutputGenerationFooterContainer>
      <Typography color={theme.palette.text.secondary}>
        {numberOfOutputs === 0
          ? text.outputGeneration.noOutputsSelected
          : numberOfOutputs === 1
            ? `${numberOfOutputs} ${text.outputGeneration.outputSelected}`
            : `${numberOfOutputs} ${text.outputGeneration.outputsSelected}`}
      </Typography>
      <Button variant="contained" disabled={numberOfOutputs === 0} onClick={handleGenerateOutputs}>
        {text.outputGeneration.submitRequest}
      </Button>
    </OutputGenerationFooterContainer>
  );
};

export default OutputGenerationFooter;
