import BillOfMaterials from './BillOfMaterials/BillOfMaterial';
import Drawing2D from './Drawing2D/Drawing2D';
import Model3D from './Model3D/Model3D';
import NeutralFormat from './NeutralFormat/NeutralFormat';
import {
  getAllOutputs,
  isBomBasedOutput,
  isDrawingTemplateBasedOutput,
  isModelBasedOutput,
  isNeutralFormatBasedOutput,
} from 'mid-utils';
import { DCProductUIExtension, VariantLmvInstance } from 'mid-types';
import { ProductRelease } from '@adsk/offsite-dc-sdk';
import Divider from '@mui/material/Divider';

interface OutputSelectionPageProps {
  selectedProductRelease?: DCProductUIExtension<ProductRelease>;
  selectedInstances?: VariantLmvInstance[];
}

const OutputSelectionPage: React.FC<OutputSelectionPageProps> = ({ selectedProductRelease, selectedInstances }) => {
  const productReleaseOutputs = getAllOutputs(selectedProductRelease, selectedInstances);
  const outputsTypeModel3D = productReleaseOutputs.filter(isModelBasedOutput);
  const outputsTypeDrawing = productReleaseOutputs.filter(isDrawingTemplateBasedOutput);
  const outputsTypeBom = productReleaseOutputs.filter(isBomBasedOutput);
  const outputsTypeNeutralFormat = productReleaseOutputs.filter(isNeutralFormatBasedOutput);

  const Components = [
    outputsTypeModel3D.length > 0 ? <Model3D /> : null,
    outputsTypeDrawing.length > 0 ? <Drawing2D outputsTypeDrawing={outputsTypeDrawing} /> : null,
    outputsTypeBom.length > 0 ? <BillOfMaterials outputsTypeBom={outputsTypeBom} /> : null,
    outputsTypeNeutralFormat.length > 0 ? <NeutralFormat outputsTypeNeutralFormat={outputsTypeNeutralFormat} /> : null,
  ];

  return Components.reduce((PreviousComponents, Component) => {
    if (PreviousComponents && Component) {
      return (
        <>
          {PreviousComponents}
          <Divider />
          {Component}
        </>
      );
    }

    if (PreviousComponents) {
      return PreviousComponents;
    }

    return Component;
  });
};

export default OutputSelectionPage;
