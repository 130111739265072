import { PostVariantOutputWithVirtualTypes } from 'mid-types';
import { createStore, StoreApi } from 'zustand';

export interface OutputTypesProps {
  // MODEL 3D
  isIamSelected: boolean;

  // DRAWING 2D
  idwOutputs: string[];
  dwgOutputs: string[];
  pdfOutputs: string[];

  // BILL OF MATERIALS
  bomOutputs: string[];
  aggregatedBomOutputs: string[];

  // NEUTRAL FORMAT
  satOutputs: string[];
  stepOutputs: string[];
  glbOutputs: string[];
  stlOutputs: string[];

  allOutputs: PostVariantOutputWithVirtualTypes[];
}

export interface OutputTypesSlice extends OutputTypesProps {
  // MODEL 3D
  setIsIamSelected: (isIamSelected: boolean) => void;

  // DRAWING 2D
  setIdwOutputs: (idwOutputs: string[]) => void;
  setDwgOutputs: (dwgOutputs: string[]) => void;
  setPdfOutputs: (pdfOutputs: string[]) => void;

  // BILL OF MATERIALS
  setBomOutputs: (bomOutputs: string[]) => void;
  setAggregatedBomOutputs: (aggregatedBomOutputs: string[]) => void;

  // NEUTRAL FORMAT
  setSatOutputs: (satOutputs: string[]) => void;
  setStepOutputs: (stepOutputs: string[]) => void;
  setGlbOutputs: (glbOutputs: string[]) => void;
  setStlOutputs: (stlOutputs: string[]) => void;
  setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => void;
  resetAllOutputs: () => void;
}

export const emptyOutputs: OutputTypesProps = {
  isIamSelected: false,
  idwOutputs: [],
  dwgOutputs: [],
  pdfOutputs: [],
  bomOutputs: [],
  aggregatedBomOutputs: [],
  satOutputs: [],
  stepOutputs: [],
  glbOutputs: [],
  stlOutputs: [],
  allOutputs: [],
};

export const createOutputTypesStore = (initProps?: Partial<OutputTypesProps>): StoreApi<OutputTypesSlice> =>
  createStore<OutputTypesSlice>()((set) => ({
    // MODEL 3D
    isIamSelected: false,
    setIsIamSelected: (isIamSelected: boolean) => set({ isIamSelected }),

    // DRAWINGS 2D
    idwOutputs: [],
    setIdwOutputs: (idwOutputs: string[]) => set({ idwOutputs }),

    dwgOutputs: [],
    setDwgOutputs: (dwgOutputs: string[]) => set({ dwgOutputs }),

    pdfOutputs: [],
    setPdfOutputs: (pdfOutputs: string[]) => set({ pdfOutputs }),

    // BILL OF MATERIALS
    bomOutputs: [],
    setBomOutputs: (bomOutputs: string[]) => set({ bomOutputs }),

    aggregatedBomOutputs: [],
    setAggregatedBomOutputs: (aggregatedBomOutputs: string[]) => set({ aggregatedBomOutputs }),

    // NEUTRAL FORMAT
    satOutputs: [],
    setSatOutputs: (satOutputs: string[]) => set({ satOutputs }),

    stepOutputs: [],
    setStepOutputs: (stepOutputs: string[]) => set({ stepOutputs }),

    glbOutputs: [],
    setGlbOutputs: (glbOutputs: string[]) => set({ glbOutputs }),

    stlOutputs: [],
    setStlOutputs: (stlOutputs: string[]) => set({ stlOutputs }),

    // All outputs
    allOutputs: [],
    setAllOutputs: (allOutputs: PostVariantOutputWithVirtualTypes[]) => set({ allOutputs }),
    resetAllOutputs: () => {
      set(emptyOutputs);
    },

    ...initProps,
  }));
