import { ReleaseStatus } from '@adsk/offsite-dc-sdk';
import { FC, useContext, useEffect } from 'react';
import { ProductCustomizationForm, usePrintMessageDialog } from '@mid-react-common/addins';
import { ProductCustomizationFooter, useRepresentation } from '@mid-react-common/revit-components';
import { ModalContext, NotificationContext, useProductCustomizationForm, VariantFormState } from '@mid-react-common/common';
import { useShallow } from 'zustand/react/shallow';
import { mainStoreActions, useMainStore } from '../../stores/MainStore';
import { useFormActions } from './useFormActions';
import { ProductCustomizationFormWrapper } from './FormPanel.styles';
import { MAX_INPUTS_VIEWPORT } from './constants';
import { createPortal } from 'react-dom';
import { dockingPanelFooterCSSClass, formPanelId } from '../../utils/consts';
import { getContentFromObjectKey } from 'mid-api-services';
import { inputsRulesToDCRules, ProductReleaseError } from 'mid-utils';
import { InputRule } from 'mid-types';
import text from '../../utils/text.json';

interface FormPanelProps {
  createPortalForFooter?: boolean;
}

export const FormPanel: FC<FormPanelProps> = ({ createPortalForFooter = true }) => {
  const { dontShowAgain, setModalState } = useContext(ModalContext);
  const { showNotification, logAndShowNotification } = useContext(NotificationContext);

  const { showMessageDialog } = usePrintMessageDialog();

  const {
    currentProductRelease,
    configurableProductProperties,
    selectedRepresentation,
    isFormInitializing,
    variantFormState,
    incomingAccBridgeData,
  } = useMainStore(
    useShallow((state) => ({
      currentProductRelease: state.currentProductRelease,
      configurableProductProperties: state.configurableProductProperties,
      selectedRepresentation: state.selectedRepresentation,
      isFormInitializing: state.isFormInitializing,
      variantFormState: state.variantFormState,
      incomingAccBridgeData: state.incomingAccBridgeData,
    })),
  );
  const {
    updateConfigurableProductInputs,
    setVariantFormState,
    setIsFormInitializing,
    setSelectedRepresentation,
    resetConfigurableProductProperties,
  } = mainStoreActions;

  const inputsQty =
    configurableProductProperties.inputs.length > MAX_INPUTS_VIEWPORT
      ? MAX_INPUTS_VIEWPORT
      : configurableProductProperties.inputs.length;

  const {
    productCustomizationFormError,
    isFormDataValid,
    setIsFormDataValid,
    inputsError,
    handleInputUpdate,
    formRules,
    tabValue,
    handleTabChange,
  } = useProductCustomizationForm({
    currentProductRelease,
    configurableProductProperties,
    updateConfigurableProductInputs,
    setVariantFormState,
    logAndShowNotification,
    showMessageDialog,
    isFormInitializing,
    setIsFormInitializing,
  });

  const { handleGenerateNewVariantClick, handleResetClick } = useFormActions({
    currentProductRelease,
    configurableProductProperties,
    selectedRepresentation,
    dontShowAgain,
    incomingAccBridgeData,
    setVariantFormState,
    setModalState,
    showNotification,
    setIsFormInitializing,
    resetConfigurableProductProperties,
  });

  const handleRepresentationStateChange = (): void => {
    if (setVariantFormState) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);
    }
  };

  const { rfaRepresentationDropdownItems, selectedRepresentationDropdownItem, handleRfaRepresentationSelection } =
    useRepresentation({
      configurableProductProperties,
      selectedRepresentation,
      setSelectedRepresentation,
      handleRepresentationStateChange,
    });

  // Get the rules for the current product release
  useEffect(() => {
    const updateProductReleaseWithRules = async (): Promise<void> => {
      try {
        if (currentProductRelease?.rulesKey) {
          const productRules = await getContentFromObjectKey<InputRule[]>({
            tenancyId: currentProductRelease.tenancyId,
            objectKey: currentProductRelease.rulesKey,
            incomingAccBridgeData,
          });

          if (productRules && productRules.length > 0) {
            const transformedToDCRules = inputsRulesToDCRules(productRules);

            mainStoreActions.setCurrentProductRelease({ ...currentProductRelease, rules: transformedToDCRules });
            mainStoreActions.setIsFormInitializing(true);
          }
        }
      } catch (error) {
        throw new ProductReleaseError(text.form.productReleaseMissingRulesError, { currentProductRelease });
      }
    };

    if (currentProductRelease && !currentProductRelease.rules) {
      updateProductReleaseWithRules();
    }
  }, [currentProductRelease, incomingAccBridgeData]);

  if (!currentProductRelease) {
    return null;
  }

  const dockingPanelFooter = document.getElementById(formPanelId)?.querySelector(dockingPanelFooterCSSClass);
  const FooterComponent = (
    <ProductCustomizationFooter
      handleResetClick={handleResetClick}
      handleGenerateNewVariantClick={handleGenerateNewVariantClick}
      handleRfaRepresentationSelection={handleRfaRepresentationSelection}
      variantFormState={variantFormState}
      isFormDataValid={isFormDataValid}
      selectedRepresentationDropdownItem={selectedRepresentationDropdownItem}
      representationDropdownItems={rfaRepresentationDropdownItems}
      currentProductReleaseStatus={currentProductRelease?.status}
      isProductConfigurable={currentProductRelease.isConfigurable}
    />
  );
  const FooterToRender = createPortalForFooter ? createPortal(FooterComponent, dockingPanelFooter!) : FooterComponent;

  return (
    <div>
      <ProductCustomizationFormWrapper inputsQty={inputsQty}>
        <ProductCustomizationForm
          inactive={
            isFormInitializing ||
            currentProductRelease.status === ReleaseStatus.OBSOLETE ||
            variantFormState === VariantFormState.GENERATING_NEW_VARIANT
          }
          error={productCustomizationFormError}
          inputs={configurableProductProperties.inputs}
          inputsError={inputsError}
          handleInputUpdate={handleInputUpdate}
          setIsFormDataValid={setIsFormDataValid}
          formLayoutRules={formRules}
          isFormLoading={isFormInitializing || !currentProductRelease.rules}
          isProductConfigurable={currentProductRelease.isConfigurable}
          tabValue={tabValue}
          handleTabChange={handleTabChange}
        />
      </ProductCustomizationFormWrapper>
      {FooterToRender}
    </div>
  );
};
