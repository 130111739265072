import { OutputType, PostVariantPayload, ProductRelease, Variant } from '@adsk/offsite-dc-sdk';
import { logError, ProductReleaseError, VariantPostError } from 'mid-utils';
import { revitComponentsText } from '@mid-react-common/revit-components';
import {
  ConfigurableProductProperties,
  NOTIFICATION_STATUSES,
  ShowNotificationProps,
  StateSetter,
  VariantFormState,
  VariantFormStates,
  ModalState,
} from '@mid-react-common/common';
import { AccBridgeSourceProjectDataQueryParams, DCProductUIExtension } from 'mid-types';
import { useCallback } from 'react';
import { transformToVariantPayloadRfaAndSvfOnly } from '@mid-react-common/addins';
import text from '../../utils/text.json';
import { getDcApiServiceInstance } from 'mid-api-services';

interface UseFormActionsProps {
  currentProductRelease: DCProductUIExtension<ProductRelease> | undefined;
  configurableProductProperties: ConfigurableProductProperties;
  selectedRepresentation: string | undefined;
  dontShowAgain: boolean | undefined;
  incomingAccBridgeData: AccBridgeSourceProjectDataQueryParams | undefined;
  setVariantFormState: (variantFormState: VariantFormStates) => void;
  setModalState: StateSetter<ModalState>;
  showNotification: (props: ShowNotificationProps) => void;
  resetConfigurableProductProperties: () => void;
  setIsFormInitializing: (isFormInitializing: boolean) => void;
}

interface UseFormActionsReturn {
  handleGenerateNewVariantClick: () => Promise<void>;
  handleResetClick: () => void;
}

export const useFormActions = ({
  currentProductRelease,
  configurableProductProperties,
  selectedRepresentation,
  dontShowAgain,
  incomingAccBridgeData,
  setVariantFormState,
  setModalState,
  showNotification,
  resetConfigurableProductProperties,
  setIsFormInitializing,
}: UseFormActionsProps): UseFormActionsReturn => {
  const postVariant = useCallback(async (): Promise<Variant> => {
    if (!currentProductRelease) {
      throw new ProductReleaseError(text.form.productReleaseMissingError, {
        currentProductRelease,
      });
    }

    const { tenancyId, contentId, release } = currentProductRelease;

    if (!selectedRepresentation) {
      throw new VariantPostError(text.form.selectedRepresentationsIsMissing, { projectId: tenancyId, productId: contentId });
    }

    const postVariantPayload: PostVariantPayload = {
      ...transformToVariantPayloadRfaAndSvfOnly(
        {
          ...configurableProductProperties,
          inputs: currentProductRelease?.isConfigurable ? configurableProductProperties.inputs : [],
        },
        selectedRepresentation,
      ),
      release,
    };
    const createdVariant = await getDcApiServiceInstance().postVariant({
      projectId: tenancyId,
      productId: currentProductRelease?.contentId,
      postVariantPayload,
      incomingAccBridgeData,
    });

    return createdVariant;
  }, [configurableProductProperties, currentProductRelease, selectedRepresentation, incomingAccBridgeData]);

  const generateNewVariant = async () => {
    try {
      setVariantFormState(VariantFormState.GENERATING_NEW_VARIANT);
      const postedVariant = await postVariant();

      // calling Segment event handler
      // TODO: handle Segment Variants Generate Event
      // handleSegmentVariantsGenerateEvent(sessionId, postedVariant); JIRA: TRADES-7285

      if (hasVariantBeenPreviouslyGenerated(postedVariant, selectedRepresentation)) {
        setVariantFormState(VariantFormState.EXISTING_VARIANT_SELECTED);
        showNotification({
          message: text.form.variantPreviouslyGenerated,
          severity: NOTIFICATION_STATUSES.SUCCESS,
          autoDismiss: true,
        });
      } else {
        setVariantFormState(VariantFormState.VARIANT_RFA_OUTPUT_PENDING);
        showNotification({
          message: revitComponentsText.sentForGeneration,
          severity: NOTIFICATION_STATUSES.SUCCESS,
          autoDismiss: true,
        });
      }
    } catch (err) {
      setVariantFormState(VariantFormState.EDITING_NEW_VARIANT);

      logError(err);
      showNotification({
        message: revitComponentsText.failGenerate,
        severity: NOTIFICATION_STATUSES.ERROR,
      });
    }
  };

  const hasVariantBeenPreviouslyGenerated = (variant: Variant, variantRepresentation: string | undefined) =>
    variant?.outputs.some(
      (output) => output.type === OutputType.RFA && output.modelState === variantRepresentation && output.urn,
    ) &&
    variant?.outputs.some(
      (output) => output.type === OutputType.THUMBNAIL && output.modelState === variantRepresentation && output.urn,
    );

  const handleGenerateNewVariantClick = async () => {
    if (!dontShowAgain) {
      setModalState({
        isOpen: true,
        title: revitComponentsText.generateNow,
        message: revitComponentsText.generateModalMessage,
        confirmButtonLabel: revitComponentsText.generate,
        cancelButtonLabel: revitComponentsText.dontGenerate,
        dontShowAgainMessage: revitComponentsText.dontShowAgainMessage,
        onConfirmCallback: generateNewVariant,
      });
    } else {
      await generateNewVariant();
    }
  };

  const handleResetClick = (): void => {
    resetConfigurableProductProperties();
    setIsFormInitializing(true);
    setVariantFormState(VariantFormState.DEFAULT_VARIANT);
  };

  return {
    handleGenerateNewVariantClick,
    handleResetClick,
  };
};
