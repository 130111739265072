import { DownloadURLPayload, DownloadUrlResponse } from '@adsk/offsite-dc-sdk';
import { AccBridgeDownloadUrlQueryParams } from '../dcApi.service';
import axios from 'axios';
import { getDcApiServiceInstance } from '../inversify/inversify.config';

interface GetContentFromObjectKeyArgs {
  tenancyId: string;
  objectKey: string;
  incomingAccBridgeData?: AccBridgeDownloadUrlQueryParams;
}

/**
 * Gets the content from an object key.
 * @param tenancyId
 * @param objectKey
 * @param incomingAccBridgeData
 */
export const getContentFromObjectKey = async <T>({
  tenancyId,
  objectKey,
  incomingAccBridgeData,
}: GetContentFromObjectKeyArgs): Promise<T> => {
  const downloadURLPayload: DownloadURLPayload = {
    objectKey,
  };
  const downloadURLResponse: DownloadUrlResponse = await getDcApiServiceInstance().downloadURL({
    projectId: tenancyId,
    downloadURLPayload,
    incomingAccBridgeData,
  });
  const axiosInstance = axios.create();
  const response = await axiosInstance.get(downloadURLResponse.signedUrl);
  return response.data as T;
};
