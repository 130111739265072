import { Spacing } from '@mid-react-common/common';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { DCNeutralOutputs, OutputTypeWithVirtualTypes } from 'mid-types';
import text from '../../../common.text.json';
import testIds from '../../../tests/helpers/dataTestIds';
import { Section } from '../OutputSelectionPage.styles';
import NeutralFormatDropdown from './NeutralFormatDropdown';
import useNeutralFormat from './useNeutralFormat';
import { useOutputSelectionStore } from '../OutputSelectionContext/OutputSelectionContext';

const outputSelectionText = text.outputSelection;

interface NeutralFormatProps {
  outputsTypeNeutralFormat: DCNeutralOutputs[];
}

const NeutralFormat: React.FC<NeutralFormatProps> = ({ outputsTypeNeutralFormat }) => {
  const { allOutputs, glbOutputs, satOutputs, stepOutputs, setAllOutputs, setGlbOutputs, setSatOutputs, setStepOutputs } =
    useOutputSelectionStore((state) => ({
      allOutputs: state.allOutputs,
      glbOutputs: state.glbOutputs,
      satOutputs: state.satOutputs,
      stepOutputs: state.stepOutputs,
      setAllOutputs: state.setAllOutputs,
      setGlbOutputs: state.setGlbOutputs,
      setSatOutputs: state.setSatOutputs,
      setStepOutputs: state.setStepOutputs,
    }));

  const {
    handleGlbOutputsChange,
    handleSatOutputsChange,
    handleStepOutputsChange,
    handleSatOutputDelete,
    handleStepOutputDelete,
    handleGlbOutputDelete,
  } = useNeutralFormat({
    outputsTypeNeutralFormat,
    allOutputs,
    glbOutputs,
    satOutputs,
    stepOutputs,
    setAllOutputs,
    setGlbOutputs,
    setSatOutputs,
    setStepOutputs,
  });

  return (
    <Section data-testid={testIds.outputSelection.neutralFormatSection}>
      <Typography fontWeight="bold" variant="h3">
        {outputSelectionText.neutralFormat}
      </Typography>
      <Spacing />
      <Box>
        {outputsTypeNeutralFormat.map((outputsByType, index) => {
          switch (outputsByType.type) {
            case OutputTypeWithVirtualTypes.SAT:
              return (
                <NeutralFormatDropdown
                  key={`${outputsByType.type}-${index}`}
                  label={outputSelectionText.neutralSAT}
                  modelStates={outputsByType.options.modelStates}
                  selectedModelStates={satOutputs}
                  handleOnChange={handleSatOutputsChange}
                  handleDelete={handleSatOutputDelete}
                />
              );
            case OutputTypeWithVirtualTypes.STEP:
              return (
                <NeutralFormatDropdown
                  key={`${outputsByType.type}-${index}`}
                  label={outputSelectionText.neutralStep}
                  modelStates={outputsByType.options.modelStates}
                  selectedModelStates={stepOutputs}
                  handleOnChange={handleStepOutputsChange}
                  handleDelete={handleStepOutputDelete}
                />
              );
            case OutputTypeWithVirtualTypes.GLB:
              return (
                <NeutralFormatDropdown
                  key={`${outputsByType.type}-${index}`}
                  label={outputSelectionText.neutralGLB}
                  modelStates={outputsByType.options.modelStates}
                  selectedModelStates={glbOutputs}
                  handleOnChange={handleGlbOutputsChange}
                  handleDelete={handleGlbOutputDelete}
                />
              );
          }
        })}
      </Box>
    </Section>
  );
};

export default NeutralFormat;
