import { LinearProgress } from '@mui/material';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import React from 'react';
import { dataGridDefaultSettings } from '../../../global/constants/products';
import { Instance } from '../../../types/product';
import { InstancesDataGrid } from './ProductsInstancesDataGrid.style';
import { useProductsInstancesDataGrid } from './useProductsInstancesDataGrid';

interface ProductsInstancesDataGridProps {
  midModelInstances: Instance[];
  selectedInstancesIds: string[] | undefined;
  columns: GridColDef<GridValidRowModel>[];
  tableLoading: boolean;
  selectionFilter: string;
  handleInstancesSelection: (a: any) => void;
}

const ProductsInstancesDataGrid: React.FC<ProductsInstancesDataGridProps> = ({
  midModelInstances,
  selectedInstancesIds,
  columns,
  tableLoading,
  selectionFilter,
  handleInstancesSelection,
}) => {
  const { instancesFilterModel } = useProductsInstancesDataGrid({ selectionFilter });

  return (
    <InstancesDataGrid
      slots={{
        loadingOverlay: () => <LinearProgress />,
      }}
      rows={midModelInstances}
      columns={columns}
      pageSizeOptions={dataGridDefaultSettings.ROWS_PER_PAGE}
      checkboxSelection
      disableRowSelectionOnClick
      rowHeight={dataGridDefaultSettings.ROW_HEIGHT}
      columnHeaderHeight={dataGridDefaultSettings.HEADER_HEIGHT}
      rowSelectionModel={selectedInstancesIds}
      onRowSelectionModelChange={handleInstancesSelection}
      loading={tableLoading}
      filterModel={instancesFilterModel}
    />
  );
};

export default ProductsInstancesDataGrid;
