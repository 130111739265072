import { createRoot } from 'react-dom/client';
import { generateDomId } from '../../utils/common';
import { Button } from '../Button/Button';

import text from '../../utils/text.json';
import { ILmvElement } from '../../utils/types';
import { DockingPanel } from '../../components/DockingPanel/DockingPanel';
import FormPanelApp from './FormPanelApp';
import { FORM_FOOTER_HEIGHT, FORM_HEADER_HEIGHT, INPUT_HEIGHT, MAX_INPUTS_VIEWPORT, PANEL_HEADER_HEIGHT } from './constants';
import { useMainStore } from 'stores/MainStore';
import { dockingPanelFooterCSSClass, dockingPanelTitleCSSClass, formPanelSuffixId } from '../../utils/consts';

export class FormLmvElement implements ILmvElement {
  _viewer: Autodesk.Viewing.GuiViewer3D;
  _panel: DockingPanel;
  _button: Autodesk.Viewing.UI.Button;

  constructor(viewer: Autodesk.Viewing.GuiViewer3D) {
    this._viewer = viewer;

    this._button = this._createButton();

    this._panel = this._createPanel();
  }

  _createButton(): Autodesk.Viewing.UI.Button {
    const button = new Autodesk.Viewing.UI.Button(generateDomId('formButton'));

    const reactRoot = createRoot(button.container);

    // remove default icon container, since our buttons are text only
    button.container.removeChild(button.icon);

    reactRoot.render(<Button text={text.form.buttonText} onClick={this._onButtonClick.bind(this)} width={50} />);

    return button;
  }

  _createPanel(): DockingPanel {
    const panel = new DockingPanel(this._viewer, {
      id: generateDomId(formPanelSuffixId),
      title: text.form.panelTitlePlaceholder,
      x: 100,
      y: 100,
      width: 410,
      height: 400,
      handleClose: () => {
        this._button.setState(Autodesk.Viewing.UI.Button.State.INACTIVE);
      },
    });

    panel.renderReactComponent(<FormPanelApp />);

    const panelFooterElement = panel.container.querySelector(dockingPanelFooterCSSClass) as HTMLElement;
    panelFooterElement.style.height = '60px';
    panelFooterElement.style.zIndex = '2';

    return panel;
  }

  _updatePanelStyles(): void {
    const currentProductRelease = useMainStore.getState().currentProductRelease;

    const inputsQty =
      currentProductRelease && currentProductRelease.inputs.length > MAX_INPUTS_VIEWPORT
        ? MAX_INPUTS_VIEWPORT
        : currentProductRelease?.inputs.length;

    this._panel.title = currentProductRelease?.isConfigurable
      ? text.form.panelTitleConfigurable
      : text.form.panelTitleStatic;
    this._panel.container.style.height =
      INPUT_HEIGHT * (inputsQty || 1) + FORM_HEADER_HEIGHT + FORM_FOOTER_HEIGHT + PANEL_HEADER_HEIGHT + 'px';

    const panelTitleElement = this._panel.container.querySelector(dockingPanelTitleCSSClass) as HTMLElement;
    panelTitleElement.innerHTML = this._panel.title;
  }

  _onButtonClick(): void {
    if (this._button.getState() !== Autodesk.Viewing.UI.Button.State.DISABLED) {
      this._button.setState(Autodesk.Viewing.UI.Button.State.ACTIVE);
      this._updatePanelStyles();
      this._panel.setVisible(true);
    }
  }

  getButton(): Autodesk.Viewing.UI.Button {
    return this._button;
  }

  uninitialize(): void {
    // removes visibility listeners
    this._panel.uninitialize();
  }
}
