import { DCOutput, DCRfaOutput, DCSVFOutput, OutputType, ProductRelease } from '@adsk/offsite-dc-sdk';
import { groupBy } from 'lodash';
import { DCOutputWithVirtualTypes, DCOutputAggregatedBom, OutputTypeWithVirtualTypes, VariantLmvInstance } from 'mid-types';
import { getDCOutputModelStates } from 'mid-utils';

export const removeOutputFileExtension = (fileName: string): string => {
  const fileExtensions = ['.zip', '.dwg', '.idw', '.pdf', '.csv', '.sat', '.glb', '.step'];
  const extensionOfFileName = fileExtensions.find((ext) => fileName.endsWith(ext));
  if (extensionOfFileName) {
    return fileName.replace(extensionOfFileName, '');
  }
  return fileName;
};

export const extractFilename = (filepath: string): string => {
  const fileName = filepath.split('\\').pop();
  if (fileName) {
    return fileName;
  }
  return filepath;
};

export const getOutputFileName = (filepath: string): string => extractFilename(removeOutputFileExtension(filepath));

export const getAllOutputs = (
  selectedProductRelease: ProductRelease | undefined,
  selectedInstances: VariantLmvInstance[] | undefined,
): DCOutputWithVirtualTypes[] => {
  const outputs: DCOutputWithVirtualTypes[] =
    selectedProductRelease?.outputs.filter(
      (output) => output.type !== OutputType.RFA && output.type !== OutputType.THUMBNAIL,
    ) || [];

  const bomOutput = outputs.find((output) => output.type === OutputType.BOM);

  if (!bomOutput) {
    return outputs;
  }

  const groupedInstances = Object.values(groupBy(selectedInstances, 'variantId'));

  // the BOMs can be aggregated if there are 2 or more instances of the same variant
  const canBeAggregated = groupedInstances.some((instances) => instances.length > 1);
  if (canBeAggregated) {
    // add a new virtual output type to render the proper tree structure
    const bomAggregatedOutput: DCOutputAggregatedBom = {
      options: { modelStates: getDCOutputModelStates(bomOutput) || [] },
      type: OutputTypeWithVirtualTypes.BOMAGGREGATED,
    };
    outputs.push(bomAggregatedOutput);
  }
  return outputs;
};

export const getRFAOutput = (outputs: DCOutput[]): DCRfaOutput | undefined =>
  outputs.find((output) => output.type === OutputType.RFA);

export const getSVFOutput = (outputs: DCOutput[]): DCSVFOutput | undefined =>
  outputs.find((output) => output.type === OutputType.SVF);
